import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'  
import {withRouter} from 'react-router';
import { browserHistory } from 'react-router';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();


class Header extends Component { 
    constructor(props) {
        super(props);
        this.state = {User :""}
        var res = localStorage.getItem('user')
        this.setState({User:res})

  
    }
    componentDidUpdate(){
       var name = global.UserName
       if(name != ''){ 

       }
    }
    logOutHandler =() =>{
        global.MyCurrentAddress = 0
        global.AddedToCart = []
        global.AddedToCartUpForGrab = []
        global.UserName = ''
        global.GrandTotal = 0
        global.ShippingCost = 100
        global.User = ''
        global.SupplierUserId = ''
        localStorage.clear();
        this.setState(this.state);
        toast.success('Logged Out Successfully.', {autoClose:10000})
     } 
        
    render() {  
        return (  
                 <header className="htc__header bg--white">

            <div id="sticky-header-with-topbar" className="mainmenu__wrap sticky__header">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-2 col-sm-4 col-md-6 order-1 order-lg-1">
                            <div className="logo">
                            <NavLink to="/Home">
                                    <img src="./assests/images/logo/foody.png" alt="logo images" />
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-4 col-md-2 order-3 order-lg-2">
                            <div className="main__menu__wrap">
                                <nav className="main__menu__nav d-none d-lg-block">
                                    <ul className="mainmenu">
                                        <li className="drop"><NavLink to="/Test">Home</NavLink>
                                            <ul className="dropdown__menu">
                                                <li><NavLink to="/Home">Home Food Delivery</NavLink></li>
                                                {/* <li><NavLink to="/HomePizza">Home Pizza Delivery</NavLink></li>
                                                <li><NavLink to="/HomeBackery">Home Backery Delivery</NavLink></li>
                                                <li><NavLink to="/HomeBox">Home Box Layout</NavLink></li> */}
                                            </ul>
                                        </li>
                                        <li><NavLink to="/about">About</NavLink></li>
                                        {/* <li className="drop"><NavLink to="/MenuGrid">Menu Grid</NavLink>
                                            <ul className="dropdown__menu">
                                                <li><NavLink to="/MenuGrid">Menu Grid</NavLink></li>
                                                <li><NavLink to="/MenuList">Menu List</NavLink></li>
                                                <li><NavLink to="/MenuDetails">Menu Details</NavLink></li>
                                            </ul>
                                        </li> */}
                                        {/* <li><NavLink to="/Gallery">Gallery</NavLink></li>
                                        <li className="drop"><NavLink to="/BlogList">Blog</NavLink>
                                            <ul className="dropdown__menu">
                                                <li><NavLink to="/BlogList">Blog List</NavLink></li>
                                                <li><NavLink to="/BlogMesonry">Blog Mesonry</NavLink></li>
                                                <li><NavLink to="/BlogGrid">Blog Grid</NavLink></li>
                                                <li><NavLink to="/BlogListSideBar">Blog List Side Bar</NavLink></li>
                                                <li><NavLink to="/BlogDetails">Blog Details</NavLink></li>
                                            </ul>
                                        </li> */}
                                        {/* <li className="drop"><a href="#">Pages</a>
                                            <ul className="dropdown__menu">
                                                <li><NavLink to="/Service">Service</NavLink></li>
                                                <li><NavLink to="/CartPage">Cart Page</NavLink></li>
                                                <li><NavLink to="/CheckoutPage">Checkout Page</NavLink></li>
                                                <li><NavLink to="/ContactPage">Contact Page</NavLink></li>
                                            </ul>
                                        </li> */}
                                        {/* <li className="drop"><a href="#" >Supplier</a>
                                            <ul className="dropdown__menu">
                                            {this.state.login == 'NA'? <li><NavLink to="/SupplierSignup" onClick={this.SupplierHandler}>Supplier Signup</NavLink></li>: '' }
                                            {this.state.login == 'NA'? <li><NavLink to="/SupplierLogin" onClick={this.SupplierHandler}>Supplier Login</NavLink></li>: '' }
                                                
                                            </ul>
                                        </li> */}
                                        <li><NavLink to="/ContactPage">Contact</NavLink></li>
                                        <li><NavLink to="" style={{ fontWeight:'bold', color:'#444', fontSize:'14px'}} onClick={this.logOutHandler}>{global.UserName != '' ? 'Logout' : '' }</NavLink></li>
                                    </ul>
                                </nav>
                                
                            </div>
                        </div>
                        <div className="col-lg-1 col-sm-4 col-md-4 order-2 order-lg-3">

                            <div className="header__right d-flex justify-content-end">
                                <div className="log__in">
                                    <a className="accountbox-trigger" href="#"><span style={{marginRight:'5px', fontWeight:'bold', color:'#444', fontSize:'14px'}}>{global.UserName}</span><i className="zmdi zmdi-account-o"></i></a>
                                </div>
                                <div className="shopping__cart">
                                    <a className="minicart-trigger" href="#"><i className="zmdi zmdi-shopping-basket"></i></a>
                                    <div className="shop__qun">
                                        <span>{global.MyCurrentAddress}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="mobile-menu d-block d-lg-none"></div>
                </div>
            </div>
            
        </header>
                
        )
       
    }
}

export default withRouter(Header)

