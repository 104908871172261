import React, {Component} from 'react';
import Header from './Header';  
import {Footer} from './Footer'; 
import SignupLogin from './SignupLogin';
import {Cart} from './Cart';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'bootstrap/dist/css/bootstrap.min.css';  
import { Link } from 'react-router-dom'
import Constant from '../Helper/Constant'
import { __RouterContext } from 'react-router';
import $ from 'jquery';
import {toast} from 'react-toastify'; 
toast.configure();




export class Home extends Component 
{
    constructor(props) {
        super(props);
        
        this.state = {Categories: [], UpForGrab:"",Cakes:"",SpecialOrders:"",Desserts:"",EventsandDinners:"", Keywords:"", Typo:"", address:""}
        //window.onbeforeunload = function () {return false;}
        }

        handlerLocation = async() =>  {
            navigator.geolocation.getCurrentPosition(function(position) {
              
            fetch('https://api.umda.com.pk/api/GetAddress?Lat='+position.coords.latitude+'&Long='+position.coords.longitude)
            .then(response => response.json())
            .then(data => {   
             
                localStorage.setItem('UserAddress', data.Address)
            
            }); 

    
            });

          }

          handleTypeChange  = async(e) => {
            this.setState({
                Typo : e.target.value
            });
        }

          handleKeywordChange = async(e)=> {
            this.setState({
                Keywords : e.target.value
            });

          }

          SubmitError =() =>{
            toast.error('Please fill all Search Criteria Before Clicking Search Button.', {autoClose:10000})
         }

          handleOnClick = async() => {
              
            var keyword = this.state.Keywords
            var dropValue = this.state.Typo
            if(keyword != "" && dropValue != ""){
             this.props.history.push({ pathname: '/Searched', state: { TypoText:this.state.Typo, KeywordsText:this.state.Keywords }});
            }
            else
            {
                $( ".SearchError" ).trigger( "click" );  
            }
          }

        componentDidMount(){
            this.getBasicData();
            this.SearchData();
          }  

SearchData = async()=>{
    fetch('https://api.umda.com.pk/api/SearchSupplierList?searchKeyword=chinese&searchBy=Menu')
    .then(response => response.json())
    .then(data => {   
        this.setState({Categories:data})
    var data = this.state.Categories;
    //var CatUpForGrab = data.filter((item) => item.CategoryName == Constant.Category_UPForGrab).map(({CategoryId}) => ({CategoryId})); 

    }   
    )}       

getBasicData = async()=>{
    fetch('https://api.umda.com.pk/api/GetBasicData')
    .then(response => response.json())
    .then(data => {
        this.setState({Categories:data})
        var data = this.state.Categories;
        var CatUpForGrab = data.filter((item) => item.CategoryName == Constant.Category_UPForGrab).map(({CategoryId}) => ({CategoryId}));   
        var CatCakes = data.filter((item) => item.CategoryName == Constant.Category_Cakes).map(({CategoryId}) => ({CategoryId}));   
        var Cat_SpecialOrders = data.filter((item) => item.CategoryName == Constant.Category_SpecialOrders).map(({CategoryId}) => ({CategoryId}));   
        var CatDesserts = data.filter((item) => item.CategoryName == Constant.Category_Desserts).map(({CategoryId}) => ({CategoryId}));   
        var CatEventsandDinners = data.filter((item) => item.CategoryName == Constant.Category_EventsAndDinners).map(({CategoryId}) => ({CategoryId}));    
        (CatUpForGrab).forEach(element => {     
        var CatId = element.CategoryId;
        this.setState({UpForGrab:CatId})
        });  
        (CatCakes).forEach(element => {     
          var CatId = element.CategoryId;
          this.setState({Cakes:CatId})
          });  
        (Cat_SpecialOrders).forEach(element => {     
            var CatId = element.CategoryId;
            this.setState({SpecialOrders:CatId})
            });  
        (CatDesserts).forEach(element => {     
              var CatId = element.CategoryId;
              this.setState({Desserts:CatId})
              });
        (CatEventsandDinners).forEach(element => {     
                var CatId = element.CategoryId;
                this.setState({EventsandDinners:CatId})
                });       
        
    })
}

        
    render(){ 
        return (
            <div id="wrapper" className="wrapper">
                <Header />
            <div className="slider__area slider--one">
            <div className="slider__activation--1">
                <div className="slide fullscreen bg-image--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="slider__content">
                                    <div className="slider__inner">
                                        <h2>“UMDA”</h2>
                                        <h1>food delivery & service</h1>
                                        <div className="slider__input">
                                            <input type="text" placeholder="Search" name="Keywords" onChange={this.handleKeywordChange}/>
                                            {/* <i class="fas fa-location"></i>  */}
                                            <select className="res__search" style={{backgroundColor:"#d50c0d", color:"white" ,width:"200px", paddingLeft:"10px"}} 
                                             Value={this.state.selectValue} 
                                             onChange={this.handleTypeChange} 
                                            >
                                                <option Value=""> Please Select Category </option>
                                                <option Value="Menu"> Menu </option>
                                                <option Value="Cuisine"> Cuisine </option>
                                                <option Value="Chef"> Chef </option>
                                            </select>

                                            <div className="src__btn">
                                            <text className="src__btn" type="button" onClick={this.handleOnClick} style={{backgroundColor:"#d50c0d", color:"white" ,width:"200px" , height:"70px", textAlign:"Center", paddingTop:"22px"}}>
                                                    Search
                                            </text>
                                            <i className="fa fa-map-marker" onClick={this.handlerLocation} style={{fontSize:"30px"}}></i>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <section className="fd__service__area bg-image--2 section-padding--xlg">
            <div className="container">
                <div className="service__wrapper bg--white">
                    <div className="row">
                        <div className="col-md-12 col-lg-12">
                            <div className="section__title service__align--left">
                                <p>The process of our service</p>
                                <h2 className="title__line">How it work</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--30">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="service">
                                <div className="service__title">
                                    <div className="ser__icon">
                                        <img src="./assests/images/icon/color-icon/1.png" alt="icon image" />
                                    </div>
                                    <h2><a href="service.html">Choose restaurant</a></h2>
                                </div>
                                <div className="service__details">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="service">
                                <div className="service__title">
                                    <div className="ser__icon">
                                        <img src="./assests/images/icon/color-icon/2.png" alt="icon image" />
                                    </div>
                                    <h2><a href="service.html">Select, you love to eat</a></h2>
                                </div>
                                <div className="service__details">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div className="service">
                                <div className="service__title">
                                    <div className="ser__icon">
                                        <img src="./assests/images/icon/color-icon/3.png" alt="icon image" />
                                    </div>
                                    <h2><a href="service.html">Pickup or delivery</a></h2>
                                </div>
                                <div className="service__details">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}
        <section className="food__category__area bg--white section-padding--lg">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="section__title service__align--left">
                            <h2 className="title__line">Search by food category</h2>
                        </div>
                    </div>
                </div>
                <div className="food__category__wrapper mt--40">
                    <div className="row">
                        {/* Start Single Category */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="food__item foo">
                                <div className="food__thumb">
                                    <Link to={{ pathname: '/UpforGrab' }}>
                                        <img src="./assests/images/product/md-product/1.jpg" alt="product images" />
                                    </Link>
                                </div>
                                <div className="food__title">
                                <h2><Link to={{ pathname: '/UpforGrab' }}>Up For Grabs</Link></h2>
                                </div>
                            </div>
                        </div>
                        {/* End Single Category */}
                        {/* Start Single Category */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="food__item foo">
                                <div className="food__thumb">
                                <Link to={{ pathname: '/AllSuppliers', state: { Id: this.state.Cakes} }}>
                                        <img src="./assests/images/product/md-product/2.jpg" alt="product images" />
                                    </Link>
                                </div>
                                <div className="food__title">
                                <h2><Link to={{ pathname: '/AllSuppliers', state: { Id: this.state.Cakes} }}>Cakes</Link></h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="food__item foo">
                                <div className="food__thumb">
                                <Link to={{ pathname: '/AllSuppliers', state: { Id: this.state.SpecialOrders} }}>
                                        <img src="./assests/images/product/md-product/3.jpg" alt="product images" />
                                    </Link>
                                </div>
                                <div className="food__title">
                                <h2><Link to={{ pathname: '/AllSuppliers', state: { Id: this.state.SpecialOrders} }}>Special Orders</Link></h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="food__item foo">
                                <div className="food__thumb">
                                <Link to={{ pathname: '/AllSuppliers', state: { Id: this.state.Desserts} }}>
                                        <img src="./assests/images/product/md-product/1.jpg" alt="product images" />
                                    </Link>
                                </div>
                                <div className="food__title">
                                <h2><Link to={{ pathname: '/AllSuppliers', state: { Id: this.state.Desserts} }}>Desserts</Link></h2>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="food__item foo">
                                <div className="food__thumb">
                                <Link to={{ pathname: '/AllSuppliers', state: { Id: "1051"} }}>
                                        <img src="./assests/images/product/md-product/2.jpg" alt="product images" />
                                    </Link>
                                </div>
                                <div className="food__title">
                                <h2><Link to={{ pathname: '/AllSuppliers', state: { Id: "1051"} }}>Events and Dinners</Link></h2>
                                </div>
                            </div>
                        </div> */}
                        {/* End Single Category */}
                    </div>
                </div>
            </div>
        </section>
      
        <Footer />
        <SignupLogin />
        <Cart  /> 
        </div>
        )
    }
}