import React,{Component} from 'react';
import Header from './Header';  
import {Footer} from './Footer'; 
import SignupLogin from './SignupLogin';
import {Cart} from './Cart';
import {toast} from 'react-toastify';
import {withRouter} from 'react-router';
import { Link } from 'react-router-dom'
toast.configure();

class CartView extends Component{

    DeleteMenu(e) 
    {
      var index = global.AddedToCart.indexOf(e)
      var price = e.Price * e.ItemQuantity
      global.GrandTotal -= price
      if (index !== -1) {
         global.AddedToCart.splice(index, 1);
         global.MyCurrentAddress = global.MyCurrentAddress - 1
         this.setState(this.state);
      }
    }

    DeleteMenuCart(e) 
    {
      var index = global.AddedToCartUpForGrab.indexOf(e)
      var price = e.DealPrice * e.ItemQuantity
      global.GrandTotal -= price
      if (index !== -1) {
         global.AddedToCartUpForGrab.splice(index, 1);
         global.MyCurrentAddress = global.MyCurrentAddress - 1
         this.setState(this.state);
      }
    }


    render(){
        return(

            <div id="wrapper" className="wrapper"> 
            <Header />
            <div class="ht__bradcaump__area bg-image--18">
            <div class="ht__bradcaump__wrap d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="bradcaump__inner text-center">
                                <h2 class="bradcaump-title">Cart page</h2>
                                <nav class="bradcaump-inner">
                                  {/* <a class="breadcrumb-item" href="index.html">Home</a> */}
                                  <span class="brd-separetor"><i class="zmdi zmdi-long-arrow-right"></i></span>
                                  {/* <span class="breadcrumb-item active">cart page</span> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="cart-main-area section-padding--lg bg--white">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12 ol-lg-12">
                        <form action="#">               
                            <div class="table-content table-responsive">
                                <table>
                                    <thead>
                                        <tr class="title-top">
                                            <th class="product-thumbnail">Image</th>
                                            <th class="product-name">Product</th>
                                            <th class="product-price">Price</th>
                                            <th class="product-quantity">Quantity</th>
                                            <th class="product-subtotal">Total</th>
                                            <th class="product-remove">Remove</th>
                                        </tr>
                                    </thead>
                                    {global.AddedToCart != '' ?                                     
                                    <tbody>
                                    {global.AddedToCart.map(cats =>
                                        <tr key ={cats.MenuId}>
                                            <td class="product-thumbnail">
                                            <Link to={{ pathname: '/MenuDetails', state: { Id: cats.MenuId} }}>
                                            <img src={"https://api.umda.com.pk/uploads/"+cats.PictureUrl} alt="product img" style={{width:"101px", height:"79px"}}/>
                                            </Link>
                                            </td>
                                            <td class="product-name"><span>{cats.MenuName}</span></td>
                                            <td class="product-price"><span class="amount">{cats.Price}</span></td>
                                            <td class="product-quantity"><span>{cats.ItemQuantity}</span></td>
                                            <td class="product-subtotal">{cats.Price * cats.ItemQuantity}</td>
                                            <td class="product-remove" onClick={()=>{this.DeleteMenu(cats)}}><a href="#">X</a></td>
                                        </tr> 
                                    )}
                                    </tbody> : ''}

                                    {global.AddedToCartUpForGrab != '' ?                                     
                                    <tbody>
                                    {global.AddedToCartUpForGrab.map(cats =>
                                        <tr key ={cats.DealId}>
                                            <td class="product-thumbnail">
                                            {/* <Link to={{ pathname: '/MenuDetails', state: { Id: cats.MenuId} }}> */}
                                            <img src={"https://api.umda.com.pk/uploads/"+cats.PictureUrl} alt="product img" style={{width:"101px", height:"79px"}}/>
                                            {/* </Link> */}
                                            </td>
                                            <td class="product-name"><span>{cats.DealName}</span></td>
                                            <td class="product-price"><span class="amount">{cats.DealPrice}</span></td>
                                            <td class="product-quantity"><span>{cats.ItemQuantity}</span></td>
                                            <td class="product-subtotal">{cats.DealPrice * cats.ItemQuantity}</td>
                                            <td class="product-remove" onClick={()=>{this.DeleteMenuCart(cats)}}><a href="#">X</a></td>
                                        </tr> 
                                    )}
                                    </tbody> : ''}
                                </table>
                            </div>
                        </form> 
                        <div class="cartbox__btn">
                            <ul class="cart__btn__list d-flex flex-wrap flex-md-nowrap flex-lg-nowrap justify-content-between">
                                {/* <li><a href="#">Coupon Code</a></li>
                                <li><a href="#">Apply Code</a></li>
                                <li><a href="#">Update Cart</a></li> */}
                                {global.AddedToCart != '' || global.AddedToCartUpForGrab != '' ? <li><Link to={{ pathname: '/CheckoutPage'}}><span>Checkout</span></Link></li> : ''}
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 offset-lg-6">
                        <div class="cartbox__total__area">
                            <div class="cartbox-total d-flex justify-content-between">
                                <ul class="cart__total__list">
                                    <li>Cart total</li>
                                    <li>Shipping Cost</li>
                                </ul>
                                <ul class="cart__total__tk">
                                    <li>{global.GrandTotal}</li>
                                    <li>{global.ShippingCost}</li>
                                </ul>
                            </div>
                            <div class="cart__total__amount">
                                <span>Grand Total</span>
                            <span>{global.GrandTotal + global.ShippingCost}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </div>

            <Footer />
            <SignupLogin />
            <Cart /> 
            </div>
        )
    }
}

export default withRouter(CartView)