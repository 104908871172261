import React, { Component } from 'react'  
import {Modal,Form, Button, Toast} from 'react-bootstrap';
import Login_BLL from '../BLL/Login_BLL';
import {withRouter} from 'react-router';
import Header from './Header';  
import {Footer} from './Footer'; 
import SignupLogin from './SignupLogin';
import {Cart} from './Cart';
import $ from 'jquery';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constant from '../Helper/Constant';
import FileBase64 from 'react-file-base64';
toast.configure();

class SupplierSignup extends Component{
   constructor(props){
      super(props);
      this.state = {cat:[],  Logo: [], Categories: [], supplierNameError: "", SupplierName:"", passwordError:"", Password:"",
                   Phone:"", phoneError:"", CompanyName: "", companyNameError:"", Address:"",addressError:"", UserTypeId:"", logoURLError:"", isDisabled:true, validURL:false
      }
   }



   handleChange(e){

      if(e.target.name==='SupplierName'){
         if(e.target.value.length > 200 ){
           this.setState({
             supplierNameError:true
           })
         } else {
           this.setState({
             supplierNameError:false,     
             SupplierName:e.target.value
           })
         }
       }

       if(e.target.name==='Password'){
         if(e.target.value.length < 8 || e.target.value.length > 255 ){
           this.setState({
             passwordError:true
           })
         } else {
           this.setState({
             passwordError:false,     
             Password:e.target.value
             
           })
         }
       }
       if(e.target.name==='Phone'){
         if(e.target.value.length < 10 || e.target.value.length > 12 ){
           this.setState({
             phoneError:true
           })
         } else {
           this.setState({
             phoneError:false,     
             Phone:e.target.value
           })
         }
       }

       if(e.target.name==='CompanyName'){
         if(e.target.value.length > 250 ){
           this.setState({
             companyNameError:true
           })
         } else {
           this.setState({
             companyNameError:false,     
             CompanyName:e.target.value
           })
         }
       }
       if(e.target.name==='Address'){
        if(e.target.value.length > 250 ){
          this.setState({
            addressError:true
          })
        } else {
          this.setState({
            addressError:false,     
            Address:e.target.value
          })
        }
      }


       if(this.state.supplierNameError==="" && this.state.passwordError==="" && 
         this.state.phoneError==="" && this.state.companyNameError==="" && this.state.addressError === ""){
           this.setState({
             isDisabled:false
           })
        }

   }

   notifySuccess =() =>{
      toast.success('Mobile Verification Code has been Sent.', {autoClose:10000})
   }
   
   notifyError =() =>{
      toast.error('Email already registered try a different one.', {autoClose:10000})
   }
   
   imageUploadError =() =>{
      toast.error('Please upload image before submitting form.', {autoClose:10000})
   }
   
   formSubmitError =() =>{
      toast.error('Please fill all fields before submitting form.', {autoClose:10000})
   }
   

   getFiles(logo){
      this.setState({ Logo: logo, validURL:true })
      var base64 = logo.base64.split(',')[1];
      logo.base64 = base64;
    }

   componentDidMount(){
    this.getAllCities();
    this.getAllCategories();

  }

  getAllCities = async() =>{
    await fetch('https://api.umda.com.pk/api/GetCitiesData')
    .then(response => response.json())
    .then(data => {
        this.setState({cat:data})
    })
  }
  
  getAllCategories = async()=>{
    await fetch('https://api.umda.com.pk/api/GetBasicData')
    .then(response => response.json())
    .then(data => {
        this.setState({Categories:data})
        var data = this.state.Categories;
        var CatSupplierTypeId = data.filter((item) => item.CategoryName == Constant.Category_Supplier).map(({CategoryId}) => ({CategoryId}));       
        (CatSupplierTypeId).forEach(element => {     
        var CatId = element.CategoryId;
        this.setState({UserTypeId:CatId})
        })
  })
 }




//    onChangeHandler=event=>{
//       this.setState({
//           Logo: event.target.files[0]
//       })
//   }


// valid(){
//    if(this.state.SupplierName.length > 3){
//      alert("too many character")
//      this.setState ({ErrorName:"Invalid Length of Characters"})
//    }
// }


   handleSubmit = async (event) => {
    event.preventDefault();
    var login_bll = new Login_BLL();

    if(this.state.isDisabled === false){
      if(this.state.validURL === true){
    var dataToSend = {
              SupplierName:event.target.SupplierName.value,
              Email:event.target.Email.value,
              Password:event.target.Password.value,
              Phone:event.target.Phone.value,
              City:event.target.City.value,
              LogoURL:this.state.Logo.base64,
              CompanyName:event.target.CompanyName.value,
              Address:event.target.Address.value,
              UserTypeId:this.state.UserTypeId

     };

     event.target.reset();
     await login_bll.signup_SupplierRequest(dataToSend).then((responseJson) => {
       if(!responseJson[0])
       {
         var result = JSON.stringify(responseJson);
         if (result.includes('Successfull')) { 

         login_bll.SendMobileNo_SupplierRequest(dataToSend.Phone,responseJson.UserId).then((responseJsonMobile) => {
             if(!responseJsonMobile[0])
             {
               $( ".SupplierSignupSuccess" ).trigger( "click" );   
                this.props.history.push({  pathname: '/SupplierCodeVerification', state: { Id: responseJson.UserId, Phone: dataToSend.Phone }});
                
             }
          })

        }else{
         this.props.history.push('/SupplierSignup'); 
         $( ".SupplierSignupError" ).trigger( "click" ); 

        }
         this.setState({
           data: responseJson
        })
       }
     })
   }else{
      $( ".ImageUploadError" ).trigger( "click" ); 
   }
   }else{
      $( ".ImageUploadError" ).trigger( "click" ); 
   }
}


    render(){
        return(
            <div id="wrapper" className="wrapper">
            <Header />
            <div>
            <Modal.Body>
                     <Form onSubmit={this.handleSubmit}>

                     <Form.Group controlId ="SupplierName">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="cr-round--lg col-md-3" type="text" placeholder="Supplier Name"  required name="SupplierName" onChange={(e)=>{this.handleChange(e)}} />
       
                        </div>
                        </Form.Group>
                        <div>
                           {this.state.supplierNameError ? <span style={{color: "red"}}>Invalid Character Length</span> : ''}
                           </div> 
                        <Form.Group controlId ="Email">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="cr-round--lg col-md-3"  type="email" placeholder="Email address" required name="Email"/>
                        </div>
                        </Form.Group>
                        <Form.Group controlId ="Password">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="cr-round--lg col-md-3"  type="password" placeholder="Password" required name="Password" onChange={(e)=>{this.handleChange(e)}}/>
                        </div>
                        </Form.Group>
                        <div>
                           {this.state.passwordError ? <span style={{color: "red"}}>Password must be between 8 to 255 characters</span> : ''}
                           </div> 
                        <Form.Group controlId ="Phone">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="cr-round--lg col-md-3"  type="number" placeholder="Phone" required name="Phone" onChange={(e)=>{this.handleChange(e)}}/>
                        </div>
                        </Form.Group>
                        <div>
                           {this.state.phoneError ? <span style={{color: "red"}}>Invalid Mobile Number</span> : ''}
                           </div> 
                        <Form.Group controlId ="CompanyName">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="cr-round--lg col-md-3"  type="text" placeholder="Company Name" required name="CompanyName" onChange={(e)=>{this.handleChange(e)}}/>
                        </div>
                        </Form.Group>
                        <div>
                           {this.state.companyNameError ? <span style={{color: "red"}}>Character Lenght exceed</span> : ''}
                           </div> 
                        <Form.Group controlId ="City">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="col-md-3" as="select" required>
                               {this.state.cat.map(cats =>
                                <option key ={cats.CategoryId}>{cats.CategoryName}</option>
                                )}
                           </Form.Control>
                        </div>
                        </Form.Group>
                        <Form.Group controlId ="Address">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="cr-round--lg col-md-3"  type="text" placeholder="Address" required name="Address" onChange={(e)=>{this.handleChange(e)}}/>
                        </div>
                        </Form.Group>
                        <div>
                           {this.state.companyNameError ? <span style={{color: "red"}}>Character Lenght exceed</span> : ''}
                           </div> 
                        {/* <Form.Group controlId ="Logo">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="cr-round--lg col-md-3"  type="file" placeholder="Logo" required name="Logo" onChange={this.onChangeHandler}/>
                        </div>
                        </Form.Group> */}
                        <Form.Group controlId ="LogoURL">
                        <div className="single-input d-flex justify-content-center align-items-center col-md-11" style={{marginLeft:"10px"}}>
                          <FileBase64
                           multiple={ false }
                           onDone={ this.getFiles.bind(this)}  required />
                           </div>
                           </Form.Group>
                          

                        {/* <div className="single-input">
                           <input className="cr-round--lg" type="password" placeholder="Confirm password" />
                        </div> */}
                        <div className="single-input">
                           <button type="submit" className="food__btn"><span>Sign Up</span></button>

                        </div>
                     </Form>
                     </Modal.Body>
                     <button className="SupplierSignupSuccess" onClick={this.notifySuccess} style={{display: "none"}}>NotifySuccess</button>
                     <button className="SupplierSignupError" onClick={this.notifyError} style={{display: "none"}}>NotifyError</button>
                     <button className="ImageUploadError" onClick={this.imageUploadError} style={{display: "none"}}>ImageUploadError</button>
                     <button className="FormSubmitError" onClick={this.formSubmitError} style={{display: "none"}}>FormSubmitError</button>
            </div>
            <Footer />
            <SignupLogin />
            <Cart /> 
            </div>

        );
    }
}

export default withRouter(SupplierSignup)