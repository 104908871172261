import React, { Component } from 'react';
import Header from './Header';
import SignupLogin from './SignupLogin';
import {Cart} from './Cart';
import OwlCarousel from 'react-owl-carousel';

export class HomeBackery extends Component {
    render(){
        return(

                <div id="wrapper" className="wrapper">
                    <Header />
                    <div className="slider__area slider--three">
            <div className="slider__activation--1">
                {/* Start Single Slide */}
                <div className="slide slider__fixed--height bg-image--11 poss--relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="slider__content">
                                    <div className="slider__inner">
                                        <h2>We Are,</h2>
                                        <h1>“AAHAR”</h1>
                                        <div className="slider__btn">
                                            <a className="food__btn" href="#">Learn More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide__pizza--2 wow fadeInLeft" data-wow-delay="0.4s">
                        <img src="./assests/images/shape/sli-2.png" alt="pizza images" />
                    </div>
                    <div className="slide__pizza--3 wow fadeInRight" data-wow-delay="0.4s">
                        <img src="./assests/images/shape/sli-3.png" alt="pizza images" />
                    </div>
                </div>
                {/* End Single Slide */}
            </div>
        </div>
        {/* End Slider Area */}
        {/* Start Feature Area */}
        <section className="food__feature__area section-padding--lg bg--white">
            <div className="container">
                <div className="row">
                    {/* Start Single Feature */}
                    <div className="col-md-6 col-lg-4 col-sm-12">
                        <div className="square">
                            <div className="feature text-center">
                                <div className="feature__thumb">
                                    <a href="menu-details.html">
                                        <img src="./assests/images/service/1.png" alt="feature images" />
                                    </a>
                                </div>
                                <div className="feature__details">
                                    <h4><a href="menu-details.html">Bread & Bun</a></h4>
                                    <h6>All types of Bread Iteam are available</h6>
                                    <p>adipisicing elid tempor in dolore magna alua. Ut enim ad minim veniamexercitation llamco laboris nisi ut aliqui</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Feature */}
                    {/* Start Single Feature */}
                    <div className="col-md-6 col-lg-4 col-sm-12 sm--mt--40">
                        <div className="square">
                            <div className="feature text-center">
                                <div className="feature__thumb">
                                    <a href="menu-details.html">
                                        <img src="./assests/images/service/2.png" alt="feature images" />
                                    </a>
                                </div>
                                <div className="feature__details">
                                    <h4><a href="menu-details.html">Muffin</a></h4>
                                    <h6>All types of Muffin Iteam are available</h6>
                                    <p>adipisicing elid tempor in dolore magna alua. Ut enim ad minim veniamexercitation llamco laboris nisi ut aliqui</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Feature */}
                    {/* Start Single Feature */}
                    <div className="col-md-6 col-lg-4 col-sm-12 sm--mt--40 md--mt--40">
                        <div className="square">
                            <div className="feature text-center">
                                <div className="feature__thumb">
                                    <a href="menu-details.html">
                                        <img src="./assests/images/service/3.png" alt="feature images" />
                                    </a>
                                </div>
                                <div className="feature__details">
                                    <h4><a href="menu-details.html">Pastry & Roll</a></h4>
                                    <h6>All types of Pastry Iteam are available</h6>
                                    <p>adipisicing elid tempor in dolore magna alua. Ut enim ad minim veniamexercitation llamco laboris nisi ut aliqui</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Feature */}
                    {/* End Single Feature */}
                </div>
            </div>
        </section>
        {/* End Feature Area */}
        {/* Start Choose us Area */}
        <section className="food__choose__us__area section-padding--lg bg__cat--4 poss--relative">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-lg-12">
                        <div className="section__title title__style--2 service__align--center">
                            <h2 className="title__line">Why Choose Us</h2>
                            <p>The process of our service </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-12"> 
                        <div className="choose__wrap text-center mt--50">
                            <p>Lorem ipsutempor incididunt ut laveniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo conseq  uat. Duccaecat cupid Cganjam proident, sunt in culpa qui officia deserunt molperspiciatis.</p>
                            <ul className="fd__choose__list d-flex justify-content-center">
                                <li>1. Ontime Delivery</li>
                                <li>2. Free Delivery Cost</li>
                                <li>3. Best Quality Food</li>
                            </ul>
                            <p>t voluptatem accusantium doloremque laudantium, totaeaque ipsa quae ab illo inventore veritarchibeatae vitae dicta sunt explicabo.  voluptat evoluptas sit aspernatur aut odit aut fugit, seconsequumagni dolores eosvolupadipisci velit, sed quia non numquam eius modi tempora incidunt ut labore.</p>
                            <div className="chooseus__btn">
                                <a className="food__btn" href="#">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="choose__img--1 wow fadeInRight" data-wow-delay="0.2s">
                <img src="./assests/images/banner/bann-1/1.png" alt="banner images"/>
            </div>
            <div className="choose__img--2 wow fadeInLeft" data-wow-delay="0.3s">
                <img src="./assests/images/banner/bann-1/2.png" alt="banner images" />
            </div>
        </section>
        {/* End Choose us Area */}
        {/* Start Special Offer */}
        <section className="food__special__offer bg--white section-padding--lg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="section__title title__style--2 service__align--center">
                            <h2 className="title__line">Our Special Offer</h2>
                            <p>The process of our service </p>
                        </div>
                    </div>
                </div>
                <div className="row mt--40">
                    {/* Start Single Offer */}
                    <div className="col-md-6 col-sm-12 col-lg-3">
                        <div className="food__offer text-center foo">
                            <div className="offer__thumb poss--relative">
                                <img src="./assests/images/product/offer-product/1.jpg" alt="offer images" />
                                <div className="offer__product__prize">
                                    <span>$25</span>
                                </div>
                            </div>
                            <div className="offer__details">
                                <h2><a href="menu-details.html">Pastry Combo Pack</a></h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                <div className="offer__btn">
                                    <a className="food__btn grey--btn mid-height" href="menu-details.html">Order Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Offer */}
                    {/* Start Single Offer */}
                    <div className="col-md-6 col-sm-12 col-lg-3">
                        <div className="food__offer text-center foo">
                            <div className="offer__thumb poss--relative">
                                <img src="./assests/images/product/offer-product/2.jpg" alt="offer images" />
                                <div className="offer__product__prize">
                                    <span>$25</span>
                                </div>
                            </div>
                            <div className="offer__details">
                                <h2><a href="menu-details.html">Mixed Fruits Pie</a></h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                <div className="offer__btn">
                                    <a className="food__btn grey--btn mid-height" href="menu-details.html">Order Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Offer */}
                    {/* Start Single Offer */}
                    <div className="col-md-6 col-sm-12 col-lg-3">
                        <div className="food__offer text-center foo">
                            <div className="offer__thumb poss--relative">
                                <img src="./assests/images/product/offer-product/3.jpg" alt="offer images" />
                                <div className="offer__product__prize">
                                    <span>$25</span>
                                </div>
                            </div>
                            <div className="offer__details">
                                <h2><a href="menu-details.html">Wheat Bread</a></h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                <div className="offer__btn">
                                    <a className="food__btn grey--btn mid-height" href="menu-details.html">Order Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Offer */}
                    {/* Start Single Offer */}
                    <div className="col-md-6 col-sm-12 col-lg-3">
                        <div className="food__offer text-center foo">
                            <div className="offer__thumb poss--relative">
                                <img src="./assests/images/product/offer-product/4.jpg" alt="offer images" />
                                <div className="offer__product__prize">
                                    <span>$25</span>
                                </div>
                            </div>
                            <div className="offer__details">
                                <h2><a href="menu-details.html">Wheat Bread</a></h2>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.</p>
                                <div className="offer__btn">
                                    <a className="food__btn grey--btn mid-height" href="menu-details.html">Order Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Offer */}
                </div>
            </div>
            {/* Start Banner Area */}
            <div className="banner__area mt--40">
                <div className="container">
                    <div className="row">
                        {/* Start Single Banner */}
                        <div className="col-md-6 col-lg-3 col-sm-12">
                            <div className="banner--2 foo">
                                <div className="banner__thumb">
                                    <a href="#"><img src="./assests/images/banner/bann-2/1.jpg" alt="banner images" /></a>
                                </div>
                                <div className="banner__hover__action banner__left__bottom">
                                    <div className="banner__hover__inner">
                                        <span>20%</span>
                                        <p>off for festival</p>
                                        <h2 className="coffee-text">off for festival</h2>
                                    </div>   
                                </div>
                            </div>
                        </div>
                        {/* End Single Banner */}
                        {/* Start Single Banner */}
                        <div className="col-md-6 col-lg-3 col-sm-12">
                            <div className="banner--2 foo">
                                <div className="banner__thumb">
                                    <a href="#"><img src="./assests/images/banner/bann-2/2.jpg" alt="banner images" /></a>
                                </div>
                                <div className="banner__hover__action banner__left__top">
                                    <div className="banner__hover__inner">
                                        <h4>colorful</h4>
                                        <h2 className="pink-text">donut’s</h2>
                                        <p>get it till the stock full</p>
                                    </div>   
                                </div>
                            </div>
                        </div>
                        {/* End Single Banner */}
                        {/* Start Single Banner */}
                        <div className="col-md-12 col-lg-6 col-sm-12">
                            <div className="banner--2 foo">
                                <div className="banner__thumb">
                                    <a href="#"><img src="./assests/images/banner/bann-2/3.jpg" alt="banner images" /></a>
                                </div>
                                <div className="banner__hover__action banner__right__bottom">
                                    <div className="banner__hover__inner">
                                        <h4 className="vanilla">vanilla</h4>
                                        <h2 className="pink-text">MAFFIN</h2>
                                        <p>Lovely Food for Food lover</p>
                                    </div>   
                                </div>
                            </div>
                        </div>
                        {/* End Single Banner */}
                    </div>
                </div>
            </div>
            {/* End Banner Area */}
        </section>
        {/* End Special Offer */}
        {/* Start Popular Food Area */}
        <section className="popular__food__area section-padding--lg bg-image--12">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="section__title title__style--2 service__align--center section__bg__black">
                            <h2 className="title__line">Our Special Offer</h2>
                            <p>The process of our service </p>
                        </div>
                    </div>
                </div>
                <div className="row mt--30">
                    {/* Start Single Popular Food */}
                    <div className="col-lg-4 col-md-6 col-sm-12 foo">
                        <div className="popular__food">
                            <div className="pp_food__thumb">
                                <a href="menu-details.html">
                                    <img src="./assests/images/popular/1.jpg" alt="popular food" />
                                </a>
                                <div className="pp__food__prize active offer">
                                    <span className="new">new</span>
                                    <span>$50</span>
                                </div>
                            </div>
                            <div className="pp__food__inner">
                                <div className="pp__fd__icon">
                                    <img src="./assests/images/popular/icon/1.png" alt="icon images" />
                                </div>
                                <div className="pp__food__details">
                                    <h4><a href="menu-details.html">Food Type : Donuts</a></h4>
                                    <ul className="rating">
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                                    </ul>
                                    <p>Delivery Time : 60 min, Free Delivery</p>
                                    <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                        <div className="pp__btn">
                                            <a className="food__btn btn--transparent btn__hover--theme btn__hover--theme" href="#">Order Online</a>
                                        </div>
                                        <ul className="pp__meta d-flex">
                                            <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                            <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Popular Food */}
                    {/* Start Single Popular Food */}
                    <div className="col-lg-4 col-md-6 col-sm-12 foo">
                        <div className="popular__food">
                            <div className="pp_food__thumb">
                                <a href="menu-details.html">
                                    <img src="./assests/images/popular/2.jpg" alt="popular food" />
                                </a>
                                <div className="pp__food__prize offer">
                                    <span className="new">off</span>
                                    <span>$50</span>
                                </div>
                            </div>
                            <div className="pp__food__inner">
                                <div className="pp__fd__icon">
                                    <img src="./assests/images/popular/icon/2.png" alt="icon images" />
                                </div>
                                <div className="pp__food__details">
                                    <h4><a href="menu-details.html">Food Type : Muffin</a></h4>
                                    <ul className="rating">
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                                    </ul>
                                    <p>Delivery Time : 60 min, Free Delivery</p>
                                    <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                        <div className="pp__btn">
                                            <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                        </div>
                                        <ul className="pp__meta d-flex">
                                            <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                            <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Popular Food */}
                    {/* Start Single Popular Food */}
                    <div className="col-lg-4 col-md-6 col-sm-12 foo">
                        <div className="popular__food">
                            <div className="pp_food__thumb">
                                <a href="menu-details.html">
                                    <img src="./assests/images/popular/3.jpg" alt="popular food" />
                                </a>
                                <div className="pp__food__prize offer">
                                    <span className="new">hot</span>
                                    <span>$50</span>
                                </div>
                            </div>
                            <div className="pp__food__inner">
                                <div className="pp__fd__icon">
                                    <img src="./assests/images/popular/icon/3.png" alt="icon images" />
                                </div>
                                <div className="pp__food__details">
                                    <h4><a href="menu-details.html">Food Type : Bun</a></h4>
                                    <ul className="rating">
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                                    </ul>
                                    <p>Delivery Time : 60 min, Free Delivery</p>
                                    <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                        <div className="pp__btn">
                                            <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                        </div>
                                        <ul className="pp__meta d-flex">
                                            <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                            <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Popular Food */}
                    {/* Start Single Popular Food */}
                    <div className="col-lg-4 col-md-6 col-sm-12 foo">
                        <div className="popular__food">
                            <div className="pp_food__thumb">
                                <a href="menu-details.html">
                                    <img src="./assests/images/popular/4.jpg" alt="popular food" />
                                </a>
                                <div className="pp__food__prize active">
                                    <span>$40</span>
                                </div>
                            </div>
                            <div className="pp__food__inner">
                                <div className="pp__fd__icon">
                                    <img src="./assests/images/popular/icon/4.png" alt="icon images" />
                                </div>
                                <div className="pp__food__details">
                                    <h4><a href="menu-details.html">Food Type : Cup Cake</a></h4>
                                    <ul className="rating">
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                                    </ul>
                                    <p>Delivery Time : 60 min, Free Delivery</p>
                                    <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                        <div className="pp__btn">
                                            <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                        </div>
                                        <ul className="pp__meta d-flex">
                                            <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                            <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Popular Food */}
                    {/* Start Single Popular Food */}
                    <div className="col-lg-4 col-md-6 col-sm-12 foo">
                        <div className="popular__food">
                            <div className="pp_food__thumb">
                                <a href="menu-details.html">
                                    <img src="./assests/images/popular/5.jpg" alt="popular food" />
                                </a>
                                <div className="pp__food__prize">
                                    <span>$40</span>
                                </div>
                            </div>
                            <div className="pp__food__inner">
                                <div className="pp__fd__icon">
                                    <img src="./assests/images/popular/icon/5.png" alt="icon images" />
                                </div>
                                <div className="pp__food__details">
                                    <h4><a href="menu-details.html">Food Type : Donuts</a></h4>
                                    <ul className="rating">
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                                    </ul>
                                    <p>Delivery Time : 60 min, Free Delivery</p>
                                    <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                        <div className="pp__btn">
                                            <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                        </div>
                                        <ul className="pp__meta d-flex">
                                            <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                            <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Popular Food */}
                    {/* Start Single Popular Food */}
                    <div className="col-lg-4 col-md-6 col-sm-12 foo">
                        <div className="popular__food">
                            <div className="pp_food__thumb">
                                <a href="menu-details.html">
                                    <img src="./assests/images/popular/6.jpg" alt="popular food" />
                                </a>
                                <div className="pp__food__prize active">
                                    <span>$40</span>
                                </div>
                            </div>
                            <div className="pp__food__inner">
                                <div className="pp__fd__icon">
                                    <img src="./assests/images/popular/icon/6.png" alt="icon images" />
                                </div>
                                <div className="pp__food__details">
                                    <h4><a href="menu-details.html">Food Type : Bread</a></h4>
                                    <ul className="rating">
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li><i className="zmdi zmdi-star"></i></li>
                                        <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                                    </ul>
                                    <p>Delivery Time : 60 min, Free Delivery</p>
                                    <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                        <div className="pp__btn">
                                            <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                        </div>
                                        <ul className="pp__meta d-flex">
                                            <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                            <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Popular Food */}
                </div>
            </div>
        </section>
        {/* End Popular Food Area */}
        {/* Start Our Team Area */}
        <section className="food__team__area bg--white section-padding--lg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="section__title title__style--2 service__align--center">
                            <h2 className="title__line">Meet Our Team</h2>
                            <p>The process of our service </p>
                        </div>
                    </div>
                </div>
                <div className="row mt--40">
                    {/* Start Single Team */}
                    <div className="col-lg-4 col-md-6 col-sm-12 foo">
                        <div className="team text-center">
                            <div className="team__thumb">
                                <a href="team-details.html">
                                    <img src="./assests/images/team/team-list/1.jpg" alt="team images" />
                                </a>
                            </div>
                            <div className="team__content">
                                <div className="team__info">
                                    <h4><a href="team-details.html">Wiliam Devid</a></h4>
                                    <h6>Co-Founder</h6>
                                </div>
                                <p>adipisicing elid tempor in dolore magna alua. Ut enim ad minim veniamexercitation llamco laboris nisi ut aliqui</p>
                                <ul className="team__social__net">
                                    <li><a href="#"><i className="zmdi zmdi-google"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-rss"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-tumblr"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* End Single Team */}
                    {/* Start Single Team */}
                    <div className="col-lg-4 col-md-6 col-sm-12 foo">
                        <div className="team text-center">
                            <div className="team__thumb">
                                <a href="team-details.html">
                                    <img src="./assests/images/team/team-list/2.jpg" alt="team images" />
                                </a>
                            </div>
                            <div className="team__content">
                                <div className="team__info">
                                    <h4><a href="team-details.html">Najnin Supa</a></h4>
                                    <h6>CFounder</h6>
                                </div>
                                <p>adipisicing elid tempor in dolore magna alua. Ut enim ad minim veniamexercitation llamco laboris nisi ut aliqui</p>
                                <ul className="team__social__net">
                                    <li><a href="#"><i className="zmdi zmdi-google"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-rss"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-tumblr"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* End Single Team */}
                    {/* Start Single Team */}
                    <div className="col-lg-4 col-md-6 col-sm-12 foo">
                        <div className="team text-center">
                            <div className="team__thumb">
                                <a href="team-details.html">
                                    <img src="./assests/images/team/team-list/3.jpg" alt="team images" />
                                </a>
                            </div>
                            <div className="team__content">
                                <div className="team__info">
                                    <h4><a href="team-details.html">Devid Smith</a></h4>
                                    <h6>Supplier</h6>
                                </div>
                                <p>adipisicing elid tempor in dolore magna alua. Ut enim ad minim veniamexercitation llamco laboris nisi ut aliqui</p>
                                <ul className="team__social__net">
                                    <li><a href="#"><i className="zmdi zmdi-google"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-rss"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-tumblr"></i></a></li>
                                    <li><a href="#"><i className="zmdi zmdi-instagram"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* End Single Team */}
                </div>
            </div>
        </section>
        {/* End Our Team Area */}
        {/* Start Food Staf Area */}
        <section className="food__stuff__area bg--white poss--relative">
            <div className="food__stuff__wrap bg__cat--5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-4 col-md-12 col-sm-12">
                            <div className="food__stuff__inner">
                                <h2>Foodstuff now in your hand</h2>
                                <h3>Download Now !<br /> to get this app Faster way to order food</h3>
                                <ul className="food__stuff__link d-flex">
                                    <li><a href="#"><img src="./assests/images/app/2.png" alt="app images" /></a></li>
                                    <li><a href="#"><img src="./assests/images/app/3.png" alt="app images" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="food__sideimg--1">
                <img className="wow fadeInLeft" data-wow-delay='0.2s' src="./assests/images/app/phn.png" alt="pnone images" />
            </div>
            <div className="food__sideimg--2">
                <img src="./assests/images/banner/bann-3/1.png" alt="bg images" />
            </div>
            <div className="food__sideimg--3">
                <img src="./assests/images/banner/bann-3/2.png" alt="bg images" />
            </div>
        </section>
        {/* End Food Staf Area */}
        {/* Start Blog Area */}
        <section className="fb__blog__ara section-padding--lg bg--white blog--2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <div className="col-lg-12 col-md-12">
                            <div className="section__title title__style--2 service__align--center">
                                <h2 className="title__line">Latest News Blog</h2>
                                <p>The process of our service </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt--40">
                    {/* Start Single Blog */}
                    <div className="col-md-6 col-sm-12 col-lg-4 foo">
                        <div className="blog">
                            <div className="blog__thumb">
                                <a href="blog-details.html">
                                    <img src="./assests/images/blog/md-blog/4.jpg" alt="blog images" />
                                </a>
                            </div>
                            <div className="blog__details">
                                <h2><a href="blog-details.html">Maxican Food Fev</a></h2>
                                <span>1st Oct, 2o17</span>
                                <p>Lorem ipsum dolor sit amadipisicing elit, seddo eiusmoddolore magna aliqua. Ut enim ad miveniam, quis noion ullamco laboris nisi umt aliquip ex ea cequat.</p>
                                <div className="blog__btn">
                                    <a className="food__btn grey--btn theme--hover" href="blog-details.html">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Blog */}
                    {/* Start Single Blog */}
                    <div className="col-md-6 col-sm-12 col-lg-4 foo">
                        <div className="blog">
                            <div className="blog__thumb">
                                <a href="blog-details.html">
                                    <img src="./assests/images/blog/md-blog/5.jpg" alt="blog images" />
                                </a>
                            </div>
                            <div className="blog__details">
                                <h2><a href="blog-details.html">Italian Pizza Fev</a></h2>
                                <span>1st Oct, 2o17</span>
                                <p>Lorem ipsum dolor sit amadipisicing elit, seddo eiusmoddolore magna aliqua. Ut enim ad miveniam, quis noion ullamco laboris nisi umt aliquip ex ea cequat.</p>
                                <div className="blog__btn">
                                    <a className="food__btn grey--btn theme--hover" href="blog-details.html">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Blog */}
                    {/* Start Single Blog */}
                    <div className="col-md-6 col-sm-12 col-lg-4 foo">
                        <div className="blog">
                            <div className="blog__thumb">
                                <a href="blog-details.html">
                                    <img src="./assests/images/blog/md-blog/6.jpg" alt="blog images" />
                                </a>
                            </div>
                            <div className="blog__details">
                                <h2><a href="blog-details.html">Asian Food F</a></h2>
                                <span>1st Oct, 2o17</span>
                                <p>Lorem ipsum dolor sit amadipisicing elit, seddo eiusmoddolore magna aliqua. Ut enim ad miveniam, quis noion ullamco laboris nisi umt aliquip ex ea cequat.</p>
                                <div className="blog__btn">
                                    <a className="food__btn grey--btn theme--hover" href="blog-details.html">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Start Single Blog */}
                </div>
            </div>
        </section>
        {/* End Blog Area */}
        {/* Start Testimonial Area */}
        <section className="food__testimonial__area testimonial--3 bg-image--13">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-9 col-sm-12">
                    <OwlCarousel className="testimonia__activation testimonia__activation--3  owl-theme"
 loop
 margin={10}
 dots={false}
 responsiveClass
 items={1}
>
                            {/* Start Single Testimonial */}
                            <div className="testimonial-inner--3">
                                <div className="testimonial__inner">
                                    <div className="testimonial__content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniammco laboris nisi ut </p>
                                        <div className="test__info">
                                            <h4>Hanchika Browny</h4>
                                            <span>Food Lovers</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Testimonial */}
                                </OwlCarousel>
                    </div>
                </div>
            </div>
        </section>
        {/* End Testimonial Area */}
        {/* Start Counter Up Area */}
        <section className="fd__counterup__area bg--theme fanfact--2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="counter__up__inner d-flex flex-wrap flex-lg-nowrap flex-md-nowrap justify-content-between text-center">
                            {/* Start Single Fact */}
                            <div className="funfact">
                                <div className="fact__details">
                                    <div className="funfact__count__inner">
                                        <div className="fact__icon">
                                            <img src="./assests/images/icon/flat-icon/4.png" alt="flat icon" />
                                        </div>
                                        <div className="fact__count ">
                                            <span className="count">2456</span>
                                        </div>
                                    </div>
                                    <div className="fact__title">
                                        <h2>Menu</h2>
                                    </div>
                                </div>
                            </div> 
                            {/* End Single Fact */}
                            {/* Start Single Fact */}
                            <div className="funfact">
                                <div className="fact__details">
                                    <div className="funfact__count__inner">
                                        <div className="fact__icon">
                                            <img src="./assests/images/icon/flat-icon/5.png" alt="flat icon" />
                                        </div>
                                        <div className="fact__count ">
                                            <span className="count">2456</span>
                                        </div>
                                    </div>
                                    <div className="fact__title">
                                        <h2>Foods</h2>
                                    </div>
                                </div>
                            </div> 
                            {/* End Single Fact */}
                            {/* Start Single Fact */}
                            <div className="funfact">
                                <div className="fact__details">
                                    <div className="funfact__count__inner">
                                        <div className="fact__icon">
                                            <img src="./assests/images/icon/flat-icon/6.png" alt="flat icon" />
                                        </div>
                                        <div className="fact__count ">
                                            <span className="count">2456</span>
                                        </div>
                                    </div>
                                    <div className="fact__title">
                                        <h2>Chef</h2>
                                    </div>
                                </div>
                            </div> 
                            {/* End Single Fact */}
                            {/* Start Single Fact */}
                            <div className="funfact">
                                <div className="fact__details">
                                    <div className="funfact__count__inner">
                                        <div className="fact__icon">
                                            <img src="./assests/images/icon/flat-icon/7.png" alt="flat icon" />
                                        </div>
                                        <div className="fact__count ">
                                            <span className="count">2456</span>
                                        </div>
                                    </div>
                                    <div className="fact__title">
                                        <h2>Happy Client</h2>
                                    </div>
                                </div>
                            </div> 
                            {/* End Single Fact */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* End Counter Up Area */}
        {/* Start Our Brand Area */}
        <div className="food__brand__area section-padding--lg">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="brand__list d-flex flex-wrap flex-md-nowrap flex-lg-nowrap justify-content-between pb--60">
                            <li><a href="#"><img src="./assests/images/brand/1.png" alt="brand images" /></a></li>
                            <li><a href="#"><img src="./assests/images/brand/2.png" alt="brand images" /></a></li>
                            <li><a href="#"><img src="./assests/images/brand/3.png" alt="brand images" /></a></li>
                            <li><a href="#"><img src="./assests/images/brand/4.png" alt="brand images" /></a></li>
                            <li><a href="#"><img src="./assests/images/brand/5.png" alt="brand images" /></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        {/* End Our Brand Area */}
        {/* Start Subscribe Area */}
        <section className="fd__subscribe__wrapper bg__cat--6 poss--relative">
            <div className="fd__subscribe__area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="subscribe__inner subscribe--3">
                                <h2>Subscribe to our newsletter</h2>
                                <div id="mc_embed_signup">
                                    <div id="enter__email__address">
                                        <form action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" novalidate>
                                            <div id="mc_embed_signup_scroll" className="htc__news__inner">
                                                <div className="news__input">
                                                    <input type="email" value="" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Enter Your E-mail Address" required />
                                                </div>
                                                {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                                <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef" tabindex="-1" value="" /></div>
                                                <div className="clearfix subscribe__btn"><input type="submit" value="Send Now" name="subscribe" id="mc-embedded-subscribe" className="sign__up food__btn" />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    </div>
                                    <div className="subs__address__content d-flex justify-content-between">
                                        <div className="subs__address d-flex">
                                            <div className="sbs__address__icon">
                                                <i className="zmdi zmdi-home"></i>
                                            </div>
                                            <div className="subs__address__details">
                                                <p>Elizabeth Tower. 6th Floor <br /> Medtown, New York</p>
                                            </div>
                                        </div>
                                        <div className="subs__address d-flex">
                                            <div className="sbs__address__icon">
                                                <i className="zmdi zmdi-phone"></i>
                                            </div>
                                            <div className="subs__address__details">
                                                <p><a href="#">+088 01673-453290</a></p>
                                                <p><a href="#">+088 01773-458290</a></p>
                                            </div>
                                        </div>
                                        <div className="subs__address d-flex">
                                            <div className="sbs__address__icon">
                                                <i className="zmdi zmdi-email"></i>
                                            </div>
                                            <div className="subs__address__details">
                                                <p><a href="#">Aahardelivery@email.com</a></p>
                                                <p><a href="#">deliverydotnet@e-mail.com</a></p>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="subscri__shape--1">
                    <img src="./assests/images/banner/bann-4/1.png" alt="banner images" />
                </div>
                <div className="subscri__shape--2">
                    <img src="./assests/images/banner/bann-4/2.png" alt="banner images" />
                </div>
            </div>
        </section>
        {/* End Subscribe Area */}
        {/* Start Google Map */}
        <div className="htc__google__map">
            <div className="map-contacts">
                <div id="googleMap"></div>
            </div>
        </div>
            {/* End Google Map */}
        {/* Start Footer Area */}
        <footer className="footer__area footer--2">
            <div className="copyright bg__cat--7">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="copyright__inner">
                                <div className="cpy__right--left">
                                    <p>@All Right Reserved.<a href="https://devitems.com">Devitems</a></p>
                                </div>
                                <div className="cpy__right--right">
                                    <a href="#">
                                        <img src="./assests/images/icon/shape/2.png" alt="payment images" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <SignupLogin />
        <Cart /> 
                </div>

        );
    }

}