import React, { Component, Suspense } from 'react';  
import {Home} from './Home';
import {BrowserRouter,Route, Switch} from 'react-router-dom' ;
import {about} from './about'; 
import {HomeBox} from './HomeBox';
import {HomePizza} from './HomePizza';
import {HomeBackery} from './HomeBackery';
import  MenuGrid from './MenuGrid';
import {MenuList} from './MenuList';
import MenuDetails from './MenuDetails';
import {Gallery} from './Galllery';
import {BlogList} from './BlogList';
import {BlogMesonry} from './BlogMesonry';
import {BlogGrid} from './BlogGrid';
import {BlogListSideBar} from './BlogListSideBar';
import {BlogDetails} from './BlogDetails';
import {Service} from './Service';
import {CartPage} from './CartPage';
import CheckoutPage from './CheckoutPage';
import {ContactPage} from './ContactPage';
import Test from './Test';
import CodeVerification from './CodeVerification';
import SupplierSignup from './SupplierSignup';
import SupplierCodeVerification from './SupplierCodeVerification';
import SupplierLogin from './SupplierLogin';
import AllSuppliers from './AllSuppliers';
import UpforGrab from './UpforGrab';
import Searched from './Searched';
import CartView from './CartView';






export class Layout extends Component {  
    loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>  
    render() {  
        return (  
                <div >  
 <BrowserRouter>                     
     <Switch>
     <Route path='/' component={Home} exact/>
     <Route path='/Home' component={Home}/>
      <Route path='/about' component={about} />
      <Route path='/HomeBox' component={HomeBox} />
      <Route path='/HomePizza' component={HomePizza} />
      <Route path='/HomeBackery' component={HomeBackery} />
      <Route path='/MenuGrid' component={MenuGrid} />
      <Route path='/MenuList' component={MenuList} />
      <Route path='/MenuDetails' component={MenuDetails} />
      <Route path='/Gallery' component={Gallery} />
      <Route path='/BlogList' component={BlogList} />
      <Route path='/BlogMesonry' component={BlogMesonry} />
      <Route path='/BlogGrid' component={BlogGrid} />
      <Route path='/BlogDetails' component={BlogDetails} />
      <Route path='/BlogListSideBar' component={BlogListSideBar} />
      <Route path='/Service' component={Service} />
      <Route path='/CartPage' component={CartPage} />
      <Route path='/CheckoutPage' component={CheckoutPage} />
      <Route path='/ContactPage' component={ContactPage} />
      <Route path='/Test' component={Test} />
      <Route path='/CodeVerification' component={CodeVerification} />
      <Route path='/SupplierSignup' component={SupplierSignup} />
      <Route path='/SupplierCodeVerification' component={SupplierCodeVerification} />
      <Route path='/SupplierLogin' component={SupplierLogin} />
      <Route path='/AllSuppliers' component={AllSuppliers} />
      <Route path='/UpforGrab' component={UpforGrab} />
      <Route path='/Searched' component={Searched} />
      <Route path='/CartView' component={CartView} />

    </Switch>
    </BrowserRouter> 
    
                        
                        
                </div>  
        )  
    }  
}  


  
export default Layout  