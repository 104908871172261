
import React, { Component } from 'react'
import Constant from '../Helper/Constant';



export default  class WebRequest extends Component {

  constructor(props){
    super(props);
  }


// Post Request Function
    
     post_WebRequest = async (resourceUri, params,) => {

     
       
       let URL = Constant.URL + resourceUri;   
        var d = JSON.stringify(params);     
       return await fetch(URL, {
            method: 'POST',
        headers: {
          'Accept': 'application/json',           
          'Content-Type':'application/json'
        },
        body:d,
        })
        .then((response) => response.json())        
        .catch((error) => {
            console.log(error);
            alert(error);
        });
    }        




// Get Request Function

    
      get_WebRequest = async (resourceUri, params) => {

        console.log(Constant.URL);
        let URL = Constant.URL + resourceUri;
        console.log(URL);      
        return await fetch(URL)
        .then((response) => response.json())       
        .catch((error) => {
          console.error(error);
        });

      }


      get_WebRequest_forMobileNo = async (resourceUri, params, userId) => {
        // let PhoneNo= JSON.stringify(params);
         console.log(params);
         console.log(userId);
        let URL = Constant.URL + resourceUri +'?PhoneNo=' + params + '&userId=' + userId;
        console.log(URL);
        return await fetch(URL)
        .then((response) => response.json())       
        .catch((error) => {
          console.error(error);
        });

      }

      get_WebRequest_forUserLocations = async (resourceUri,userId) => {       
        let URL = Constant.URL + resourceUri +'?userId=' + userId;     
        return await fetch(URL)
        .then((response) => response.json())       
        .catch((error) => {
          console.error(error);
        });

      }


    
      get_WebRequest_forVerficationCode = async (resourceUri, code,userId) => {
        // let PhoneNo= JSON.stringify(params);
       
        let URL = Constant.URL + resourceUri +'?verificationCode=' + code + '&userId=' + userId;
        console.log(URL);
        return await fetch(URL)
        .then((response) => response.json())       
        .catch((error) => {
          console.error(error);
        });

      }

      get_WebRequest_forgetpassword = async (resourceUri, email) => {
        // let PhoneNo= JSON.stringify(params);
       
        let URL = Constant.URL + resourceUri +'?emailAddress=' + email;
        console.log(URL);
        return await fetch(URL)
        .then((response) => response.json())       
        .catch((error) => {
          console.error(error);
        });

      }


      

      get_WebRequest_SetLocation = async (resourceUri, lat,long, Address ,userId) => {
        // let PhoneNo= JSON.stringify(params);
       
        let URL = Constant.URL + resourceUri +'?Lat=' + lat + '&Long=' + long + '&Address=' + Address + '&userId=' + userId;
        console.log(URL);
        return await fetch(URL)
        .then((response) => response.json())       
        .catch((error) => {
          console.error(error);
        });

      }



      

      get_WebRequest_GetAddress = async (resourceUri, lat,long) => {
        // let PhoneNo= JSON.stringify(params);
       
        let URL = Constant.URL + resourceUri +'?Lat=' + lat + '&Long=' + long;
        console.log(URL);
        return await fetch(URL)
        .then((response) => response.json())       
        .catch((error) => {
          console.error(error);
        });

      }




      test_WebRequest() {

        const theData = [
          {
              name: 'Sam',
              email: 'somewhere@gmail.com'
          },
      
          {
              name: 'Ash',
              email: 'something@gmail.com'
          }
      ]
        return theData;
       
        // //let URL = Constant.URL + resourceUri;
        // fetch('https://reactnative.dev/movies.json')
        // .then((response) => response.json())
        // .then((json) => {
        
        //   // var D = json.movies.toString()
        

        //   return 'varx';
       
        // })
        // .catch((error) => {
        //   console.error(error);
        // });           

        

      }



}