import React, { Component } from 'react'  
import {Modal,Form, Button, Toast} from 'react-bootstrap';
import Login_BLL from '../BLL/Login_BLL';
import {withRouter} from 'react-router';
import Login from './Login';
import $ from 'jquery';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Constant from '../Helper/Constant';
toast.configure();


class SignupLogin extends Component{
   constructor(props){
      super(props);
      this.state = { Categories:[], nameError: "", Name:"", passwordError:"", Password:"",
                     Phone:"", phoneError:"", UserTypeId:"", isDisabled:true   
      } 
   }

   handleChange(e){

      if(e.target.name==='Name'){
         if(e.target.value.length > 200 ){
           this.setState({
             nameError:true
           })
         } else {
           this.setState({
             nameError:false,     
             Name:e.target.value
           })
         }
       }

       if(e.target.name==='Password'){
         if(e.target.value.length < 8 || e.target.value.length > 255 ){
           this.setState({
             passwordError:true
           })
         } else {
           this.setState({
             passwordError:false,     
             Password:e.target.value
             
           })
         }
       }
       if(e.target.name==='Phone'){
         if(e.target.value.length < 10 || e.target.value.length > 12 ){
           this.setState({
             phoneError:true
           })
         } else {
           this.setState({
             phoneError:false,     
             Phone:e.target.value
           })
         }
       }

       if(this.state.nameError==="" && this.state.passwordError==="" && 
         this.state.phoneError==="" ){
           this.setState({
             isDisabled:false
           })
        }

   }

notifySuccess =() =>{
   toast.success('Mobile Verification Code has been Sent.', {autoClose:10000})
}

notifyError =() =>{
   toast.error('Email already registered try a different one.', {autoClose:10000})
}

componentDidMount(){
   this.getAllCategories();

 }
 
 getAllCategories = async()=>{
   await fetch('https://api.umda.com.pk/api/GetBasicData')
   .then(response => response.json())
   .then(data => {
       this.setState({Categories:data})
       var data = this.state.Categories;
       var CatCustomerTypeId = data.filter((item) => item.CategoryName == Constant.Category_Customer).map(({CategoryId}) => ({CategoryId}));       
       (CatCustomerTypeId).forEach(element => {     
       var CatId = element.CategoryId;
       this.setState({UserTypeId:CatId})
       })
 })
}




   handleSubmit = async (event) => {
      event.preventDefault();
      var login_bll = new Login_BLL();
      if(this.state.isDisabled === false){
      var dataToSend = {
                Name:event.target.Name.value,
                Email:event.target.Email.value,
                Password:event.target.Password.value,
                Phone:event.target.Phone.value,
                UserTypeId:this.state.UserTypeId
       };

       event.target.reset();
       await login_bll.signup_Request(dataToSend).then((responseJson) => {
         if(!responseJson[0])
         {
           $( ".accountbox-close-button" ).trigger( "click" );
           var result = JSON.stringify(responseJson);
           if (result.includes('Successfull')) { 

           login_bll.SendMobileNo_Request(dataToSend.Phone,responseJson.UserId).then((responseJsonMobile) => {
               if(!responseJsonMobile[0])
               {
                  this.props.history.push({  pathname: '/CodeVerification', state: { Id: responseJson.UserId, Phone:dataToSend.Phone }});
                  $( ".SignupNotifySuccess" ).trigger( "click" );     


               }
            })

          }else{
            this.props.history.push('/Home');
            $( ".SignupNotifyError" ).trigger( "click" ); 

          }
           this.setState({
             data: responseJson
          })
         }
       })
    }
    else{
      alert("Please Fill all the fields before submitting form")
    }
   }

    render(){
        return(
            <div className="accountbox-wrapper">
            <div className="accountbox text-left">
               <ul className="nav accountbox__filters" id="myTab" role="tablist">
                  <li>
                     <a className="active log" id="log-tab" data-toggle="tab" href="#log" role="tab" aria-controls="log" aria-selected="true">Login</a>
                  </li>
                  <li>
                     <a id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Register</a>
                  </li>
               </ul>
               <div className="accountbox__inner tab-content" id="myTabContent">
               <Login />
                  <div className="accountbox__register tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"  >
                
             <Modal.Body>
                     <Form onSubmit={this.handleSubmit}>

                     <Form.Group controlId ="Name">
                        <div className="single-input">
                           <Form.Control className="cr-round--lg" type="text" placeholder="User Name" required name="Name" onChange={(e)=>{this.handleChange(e)}}/>
                        </div>
                        </Form.Group>
                        <div>
                           {this.state.nameError ? <span style={{color: "red"}}>Invalid Character Length</span> : ''}
                           </div> 
                        <Form.Group controlId ="Email">
                        <div className="single-input">
                           <Form.Control className="cr-round--lg" type="email" placeholder="Email address" required name="Email" />
                        </div>
                        </Form.Group>
                        <Form.Group controlId ="Password">
                        <div className="single-input">
                           <Form.Control className="cr-round--lg" type="password" placeholder="Password" required name="Password" onChange={(e)=>{this.handleChange(e)}}/>
                        </div>
                        </Form.Group>
                        <div>
                           {this.state.passwordError ? <span style={{color: "red"}}>Password must be between 8 to 255 characters</span> : ''}
                           </div> 
                        <Form.Group controlId ="Phone">
                        <div className="single-input">
                           <Form.Control className="cr-round--lg" type="number" placeholder="Phone" required name="Phone" onChange={(e)=>{this.handleChange(e)}}/>
                        </div>
                        </Form.Group>
                        <div>
                           {this.state.phoneError ? <span style={{color: "red"}}>Invalid Mobile Number</span> : ''}
                           </div> 
                        {/* <div className="single-input">
                           <input className="cr-round--lg" type="password" placeholder="Confirm password" />
                        </div> */}
                        <div className="single-input">
                           <button type="submit" className="food__btn"><span>Sign Up</span></button>

                        </div>
                     </Form>
                     </Modal.Body>
                     <button className="SignupNotifySuccess" onClick={this.notifySuccess} style={{display: "none"}}>NotifySuccess</button>
                     <button className="SignupNotifyError" onClick={this.notifyError} style={{display: "none"}}>NotifyError</button>
                  </div>
                  <span className="accountbox-close-button"><i className="zmdi zmdi-close"></i></span>
               </div>
            </div>
         </div>
        );
    }
}

export default withRouter(SignupLogin)