import React, { Component } from 'react'  
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import Login_BLL from '../BLL/Login_BLL';
import {withRouter} from 'react-router';
import $ from 'jquery';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

class Login extends Component{
   constructor(props){
      super(props);
   }




   loginNotifySuccess =() =>{
      toast.success('Logged In Successfully.', {autoClose:10000})
   }
   
   loginNotifyError =() =>{
      toast.error('Invalid Credentials.', {autoClose:10000})
   }
   

   handleSubmit = async (event) => {
      event.preventDefault();
      
      var login_bll = new Login_BLL();

      var dataToSend = {
                Email:event.target.Email.value,
                Password:event.target.Password.value
       };
       event.target.reset();
       await login_bll.login_Request(dataToSend).then((responseJson) => {
         if(!responseJson[0])
         {
            var result = JSON.stringify(responseJson);
            if (result.includes('Successfull')) { 

              localStorage.setItem('token', 'LoggedIn')
              localStorage.setItem('user', responseJson.Name)

              global.UserName = responseJson.Name
              global.User = responseJson
              
              this.setState(this.state);

            
            this.props.history.push('/Home');
            
         

            $( ".accountbox-close-button" ).trigger( "click" );
            $( ".LoginNotifySuccess" ).trigger( "click" );
            this.setState({
                data: responseJson
             })
            }
            else
            {
               $( ".LoginNotifyError" ).trigger( "click" );
            }

         }

       })
    }


    // handleSubmit(event) {
    //   event.preventDefault();
    //   fetch('https://api.umda.com.pk/api/Login',{
    //   method:'POST',
    //   headers:{
    //     'Accept':'application/json',
    //     'Content-Type':'application/json'
    //   },
    //   body:JSON.stringify({
    //      Email:event.target.Email.value,
    //      Password:event.target.Password.value
    //   })
    // })
    //   .then(res => res.json())
    //   .then((result)=>
    //   {
    //      var result = JSON.stringify(result)
    //      alert('Logged in Successfully.')
         

    //   },(error)=>{
    //      alert(error);
    //   }
    //   ).catch(e =>{
    //      alert(e);
    //   })
    // }



    render(){

        return(

                  <div className="accountbox__login tab-pane fade show active" id="log" role="tabpanel" aria-labelledby="log-tab">
                  <Modal.Body>
                     <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId ="Email">
                        <div className="single-input">
                           <Form.Control className="cr-round--lg" type="email" placeholder="Email address" required name="Email" />
                        </div>
                        </Form.Group>
                        <Form.Group controlId ="Password">
                        <div className="single-input">
                           <Form.Control className="cr-round--lg" type="password" placeholder="Password" required name="Password"/>
                        </div>
                        </Form.Group>
                        <div className="single-input">
                           <button type="submit" className="food__btn"><span>Login</span></button>
                        </div>
                        {/* <div className="accountbox-login__others">
                           <h6>Or login with</h6>
                           <div className="social-icons">
                              <ul>
                                 <li className="facebook"><a href="https://www.facebook.com/"><i className="fa fa-facebook"></i></a></li>
                                 <li className="twitter"><a href="https://twitter.com/"><i className="fa fa-twitter"></i></a></li>
                                 <li className="pinterest"><a href="#"><i className="fa fa-google-plus"></i></a></li>
                              </ul>
                           </div>
                        </div> */}
                     </Form>
                     </Modal.Body>
                     <button className="LoginNotifySuccess" onClick={this.loginNotifySuccess} style={{display: "none"}}>Success</button>
                     <button className="LoginNotifyError" onClick={this.loginNotifyError} style={{display: "none"}}>Error</button>
                  </div>


        );
    }

}

export default withRouter(Login)