


import React, { Component } from 'react'
import WebRequest from '../BLL/WebRequest';


export default  class Login_BLL extends Component {
 
    constructor(props){
        super(props);
    }

  
    
    login_Request (login_dto)  {

        var Request = new WebRequest();
       return Request.post_WebRequest('/api/Login', login_dto);

    };

   
  
    SendMobileNo_Request (MobileNo, userId)  {

        var Request = new WebRequest();
       return Request.get_WebRequest_forMobileNo('/api/SendPhoneCode', MobileNo,userId);

    };

   


    SendVerficationCode_Request (code,userId)
    {
        var Request = new WebRequest();
        return Request.get_WebRequest_forVerficationCode('/api/VerifyPhoneNo', code,userId);
    }

   
    

    SetLocation_Request (lat,long,Address, userId)
    {       
        var Request = new WebRequest();
        return Request.get_WebRequest_SetLocation('/api/SetLocation', lat,long,Address,userId);
    }


    GetAddress_Request (lat,long)
    {      
        var Request = new WebRequest();
        return Request.get_WebRequest_GetAddress('/api/GetAddress', lat,long);
    }


    Sendforgetpassword_Request(email)
    {
        var Request = new WebRequest();
        return Request.get_WebRequest_forgetpassword('/api/ForgetPassword', email);
    }

    

    Sendchangepassword_Request = async (login_dto) =>  {

        var Request = new WebRequest();

        console.log('login_dto');
        console.log(login_dto);


        return Request.post_WebRequest('/api/ChangePassword', login_dto);

    };


    signup_Request = async (login_dto) =>  {

        var Request = new WebRequest();
        console.log('login_dto');
        return Request.post_WebRequest('/api/Signup/', login_dto);

    };

    signup_SupplierRequest = async (login_dto) =>  {

        var Request = new WebRequest();
        console.log('login_dto');
        return Request.post_WebRequest('/api/SignupSupplier/', login_dto);

    };

    add_OrderRequest = async (Order_dto) =>  {

        var Request = new WebRequest();
        return Request.post_WebRequest('/api/AddOrder/', Order_dto);

    };


    getloginuser_data ()  {
        var Request = new WebRequest();
        return Request.test_WebRequest();
    }


    Login = (UserFound) =>{

        console.log('Login_AuthSettings',UserFound);
        // const { signIn } = React.useContext(AuthContext);
    
      }


      Address_Request (Address_dto)  {

        var Request = new WebRequest();
       return Request.post_WebRequest('/api/AddLocation', Address_dto);

    };

    
    EditAddress_Request (Address_dto)  {

        var Request = new WebRequest();
       return Request.post_WebRequest('/api/Login', Address_dto);

    };

    DeleteAddress_Request (Address_dto)  {

        var Request = new WebRequest();
       return Request.post_WebRequest('/api/DeleteLocation', Address_dto);

    };

    login_SupplierRequest (login_dto)  {

        var Request = new WebRequest();
       return Request.post_WebRequest('/api/LoginSupplier', login_dto);
    };

    SendMobileNo_SupplierRequest (MobileNo, userId)  {

        var Request = new WebRequest();
       return Request.get_WebRequest_forMobileNo('/api/SendSupplierPhoneCode', MobileNo,userId);
    };

    SendVerficationCode_SupplierRequest (code,userId)
    {
        var Request = new WebRequest();
        return Request.get_WebRequest_forVerficationCode('/api/VerifySupplierPhoneNo', code,userId);
    }






}