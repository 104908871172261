import React, { Component } from 'react';
import Header from './Header';
import {Footer} from './Footer';

export class CartPage extends Component {
    render(){
        return(
                <div id="wrapper" className="wrapper">
                    <Header />
                    <div>
                       <h1>This is Cart Page.</h1> 
                    </div>
                    <Footer />

                </div>

        );
    }

}