import React, { Component } from 'react';
import Header from './Header';
import {Footer} from './Footer';
import SignupLogin from './SignupLogin';
import {Cart} from './Cart';

export class HomePizza extends Component {
    render(){
        return(
                <div id="wrapper" className="wrapper">
                    <Header />
                    <div className="slider__area slider--two">
            <div className="slider__activation--1">
                {/* Start Single Slide */}
                <div className="slide fullscreen bg-image--7 poss--relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="slider__content">
                                    <div className="slider__inner">
                                        <div className="slider__text__img">
                                            <img className="layear-1" src="./assests/images/slider/text/2.png" alt="slider text image" />
                                            <img className="layear-2" src="./assests/images/slider/text/1.png" alt="slider text image" />
                                        </div>
                                        <div className="slider__input">
                                            <input type="text" placeholder="Type Place, City.Division" />
                                            <input className="res__search" type="text" placeholder="Restaurant" />
                                            <div className="src__btn">
                                                <a href="#">Search</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide__pizza">
                        <img src="./assests/images/shape/slider-pizza.png" alt="pizza images" />
                    </div>
                </div>
                {/* End Single Slide */}
            </div>
        </div>
        {/* End Slider Area */}
        {/* Start About Area */}
        <section className="fd__about__area section-padding--lg bg--white">
            <div className="container">
                <div className="row">
                    {/* Start Single About */}
                    <div className="col-lg-4 col-md-12 col-sm-12 d-flex align-items-center">
                        <div className="fd__about__center foo">
                            <div className="fd__about">
                                <div className="section__title service__align--left">
                                    <p>Best product, Best service</p>
                                    <h2 className="title__line">About Aahar</h2>
                                </div>
                                <h4>Best Service  For Our Customer</h4>
                                <p>Lorem ipsum dolor sit amet, cotnsectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</p>
                            </div>
                        </div>
                    </div>
                    {/* End Single About */}
                    {/* Start Single Adout */}
                    <div className="col-lg-4 col-md-6 col-sm-12 sm--mt--40 md--mt--40">
                        <div className="about wow fadeInRight" data-wow-delay="0.2s">
                            <div className="about__thumb">
                                <img src="./assests/images/about/1.jpg" alt="about images" />
                                <div className="pro__offer">
                                    <div className="pro__offer__inner">
                                        <div className="pro__offer__inner__inner">
                                            <span className="fd__diccount">50%</span>
                                            <span>Discount</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about__details">
                                <h2>For All <span>“Montanara”</span> Pizza</h2>
                                <p>Don’t Delay to Order</p>
                                <div className="about__btn">
                                    <a className="food__btn white--btn" href="about-us.html">Order Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Adout */}
                    {/* Start Single Adout */}
                    <div className="col-lg-4 col-md-6 col-sm-12 md--mt--40 sm--mt--40">
                        <div className="about bg--yellow wow fadeInRight" data-wow-delay="0.3s">
                            <div className="about__thumb">
                                <img src="./assests/images/about/2.jpg" alt="about images" />
                                <div className="pro__offer">
                                    <div className="pro__offer__inner">
                                        <div className="pro__offer__inner__inner">
                                            <span className="fd__diccount">50%</span>
                                            <span>Discount</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about__details">
                                <h2>For All <span>“Montanara”</span> Pizza</h2>
                                <p>Don’t Delay to Order</p>
                                <div className="about__btn">
                                    <a className="food__btn white--btn" href="about-us.html">Order Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Single Adout */}
                </div>
            </div>
        </section>
        {/* End About Area */}
        {/* Start Our Pizza Area */}
        <section className="fd__pizza__area bg-image--25 section-padding--lg">
            <div className="bg__cat">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section__title service__align--left">
                                <p>The process of our service </p>
                                <h2 className="title__line">Our Special Pizza</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--40">
                        {/* Start Single Pizza */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="pizza foo">
                                <div className="pizza__thumb">
                                    <img src="./assests/images/product/pizza/1.jpg" alt="pizza images" />
                                    <div className="pizza__hover__action">
                                        <span className="pizza__prize">$80</span>
                                    </div>
                                </div>
                                <div className="pizza__details">
                                    <h2 className="pizza__title"><a href="menu-details.html">Montanara</a></h2>
                                    <p>Lorem ipsum dolor sit amet, consectadipisicing labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                                    <div className="pizza__btn">
                                        <a className="food__btn white--btn theme--hover" href="menu-details.html">Order Online</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Pizza */}
                        {/* Start Single Pizza */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="pizza foo">
                                <div className="pizza__thumb">
                                    <img src="./assests/images/product/pizza/1.jpg" alt="pizza images" />
                                    <div className="pizza__hover__action">
                                        <span className="pizza__prize">$50</span>
                                    </div>
                                </div>
                                <div className="pizza__details">
                                    <h2 className="pizza__title"><a href="menu-details.html">Pepperoni</a></h2>
                                    <p>Lorem ipsum dolor sit amet, consectadipisicing labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                                    <div className="pizza__btn">
                                        <a className="food__btn white--btn theme--hover" href="menu-details.html">Order Online</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Pizza */}
                        {/* Start Single Pizza */}
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="pizza foo">
                                <div className="pizza__thumb">
                                    <img src="./assests/images/product/pizza/1.jpg" alt="pizza images" />
                                    <div className="pizza__hover__action">
                                        <span className="pizza__prize">$70</span>
                                    </div>
                                </div>
                                <div className="pizza__details">
                                    <h2 className="pizza__title"><a href="menu-details.html">Supreme</a></h2>
                                    <p>Lorem ipsum dolor sit amet, consectadipisicing labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                                    <div className="pizza__btn">
                                        <a className="food__btn white--btn theme--hover" href="menu-details.html">Order Online</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Pizza */}
                    </div>
                </div>
            </div>
            <div className="order-now-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="fd__order__now text-center">
                                <div className="order__now__inner">
                                    <h6>We Offer Free Delivery</h6>
                                    <h2>Order Now: +123654789</h2>
                                    <p>Extremely thin ,juicy,cheeze,fluffy,crispy & light weight Pizza</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* End Our Pizza Area */}
        {/* Start Counter Up Area */}
        <section className="fd__counterup__area funfact--2">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="counter__up__inner d-flex flex-wrap flex-lg-nowrap flex-md-nowrap justify-content-between text-center">
                            {/* Start Single Fact */}
                            <div className="funfact">
                                <div className="fact__details">
                                    <div className="funfact__count__inner">
                                        <div className="fact__icon">
                                            <img src="./assests/images/icon/flat-icon/1.png" alt="flat icon" />
                                        </div>
                                        <div className="fact__count ">
                                            <span className="count">2456</span>
                                        </div>
                                    </div>
                                    <div className="fact__title">
                                        <h2>Food</h2>
                                    </div>
                                </div>
                            </div> 
                            {/* End Single Fact */}
                            {/* Start Single Fact */}
                            <div className="funfact">
                                <div className="fact__details">
                                    <div className="funfact__count__inner">
                                        <div className="fact__icon">
                                            <img src="./assests/images/icon/flat-icon/2.png" alt="flat icon" />
                                        </div>
                                        <div className="fact__count">
                                            <span className="count">2056</span>
                                        </div>
                                    </div>
                                    <div className="fact__title">
                                        <h2>Chef</h2>
                                    </div>
                                </div>
                            </div> 
                            {/* End Single Fact */}
                            {/* Start Single Fact */}
                            <div className="funfact">
                                <div className="fact__details">
                                    <div className="funfact__count__inner">
                                        <div className="fact__icon">
                                            <img src="./assests/images/icon/flat-icon/3.png" alt="flat icon" />
                                        </div>
                                        <div className="fact__count ">
                                            <span className="count">3000</span>
                                        </div>
                                    </div>
                                    <div className="fact__title">
                                        <h2>Menu</h2>
                                    </div>
                                </div>
                            </div> 
                            {/* End Single Fact */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* End Counter Up Area */}
        {/* Start Resturant MEnu */}
        <section className="resturent__food__menu bg-image--8 section-padding--lg">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section__title service__align--center">
                            <p>All delicious pizza menu  for pizza lovers</p>
                            <h2 className="title__line">Restaurant with Special Menu</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div className="resturent__food__menu plr--250px mt--80 d-flex flex-wrap">
                {/* Start Single Resturent Food */}
                <div className="single__resturent__food d-flex">
                    <div className="resturent__thumb">
                        <a href="menu-details.html">
                            <img src="./assests/images/product/restu/1.png" alt="resturent image" />
                        </a>
                    </div>
                    <div className="resturent__details d-flex flex-column justify-content-center">
                        <ul className="res__pizz__size d-flex justify-content-center">
                            <li>small <span>$20</span></li>
                            <li>Medium <span>$40</span></li>
                            <li>Large <span>$50</span></li>
                        </ul>
                        <h4><a href="menu-details.html">Maxican Food Fev</a></h4>
                        <h6>Friends & Family Restaurant</h6>
                        <p>Provolone is the second most popular one. Cheddar may be mixed with Mozzarella to preserve armesan may be added to the top of a pizza,</p>
                        <div className="res__btn">
                            <a className="food__btn" href="menu-details.html">Order Now</a>
                        </div>
                    </div>
                </div>
                {/* End Single Resturent Food */}
                {/* Start Single Resturent Food */}
                <div className="single__resturent__food d-flex">
                    <div className="resturent__thumb">
                        <a href="menu-details.html">
                            <img src="./assests/images/product/restu/2.png" alt="resturent image" />
                        </a>
                    </div>
                    <div className="resturent__details d-flex flex-column justify-content-center">
                        <ul className="res__pizz__size d-flex justify-content-center">
                            <li>small <span>$20</span></li>
                            <li>Medium <span>$20</span></li>
                            <li>Large <span>$60</span></li>
                        </ul>
                        <h4><a href="menu-details.html">Italian Pizza Fev</a></h4>
                        <h6>Friends & Family Restaurant</h6>
                        <p>Provolone is the second most popular one. Cheddar may be mixed with Mozzarella to preserve armesan may be added to the top of a pizza,</p>
                        <div className="res__btn">
                            <a className="food__btn" href="menu-details.html">Order Now</a>
                        </div>
                    </div>
                </div>
                {/* End Single Resturent Food */}
                {/* Start Single Resturent Food */}
                <div className="single__resturent__food d-flex">
                    <div className="resturent__thumb">
                        <a href="menu-details.html">
                            <img src="./assests/images/product/restu/3.png" alt="resturent image" />
                        </a>
                    </div>
                    <div className="resturent__details d-flex flex-column justify-content-center">
                        <ul className="res__pizz__size d-flex justify-content-center">
                            <li>small <span>$30</span></li>
                            <li>Medium <span>$40</span></li>
                            <li>Large <span>$20</span></li>
                        </ul>
                        <h4><a href="menu-details.html">Mozzarela Cheez Pizza</a></h4>
                        <h6>Friends & Family Restaurant</h6>
                        <p>Provolone is the second most popular one. Cheddar may be mixed with Mozzarella to preserve armesan may be added to the top of a pizza,</p>
                        <div className="res__btn">
                            <a className="food__btn" href="menu-details.html">Order Now</a>
                        </div>
                    </div>
                </div>
                {/* End Single Resturent Food */}
                {/* Start Single Resturent Food */}
                <div className="single__resturent__food d-flex">
                    <div className="resturent__thumb">
                        <a href="menu-details.html">
                            <img src="./assests/images/product/restu/4.png" alt="resturent image" />
                        </a>
                    </div>
                    <div className="resturent__details d-flex flex-column justify-content-center">
                        <ul className="res__pizz__size d-flex justify-content-center">
                            <li>small <span>$10</span></li>
                            <li>Medium <span>$60</span></li>
                            <li>Large <span>$20</span></li>
                        </ul>
                        <h4><a href="menu-details.html">Mozzarela Cheez Pizza</a></h4>
                        <h6>Friends & Family Restaurant</h6>
                        <p>Provolone is the second most popular one. Cheddar may be mixed with Mozzarella to preserve armesan may be added to the top of a pizza,</p>
                        <div className="res__btn">
                            <a className="food__btn" href="menu-details.html">Order Now</a>
                        </div>
                    </div>
                </div>
                {/* End Single Resturent Food */}
            </div>
        </section>
        {/* End Resturant MEnu */}
        {/* Start Clint Demand Area */}
        <section className="clint__demand__area section-padding--lg bg--white">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section__title service__align--center">
                            <p>The process of our service </p>
                            <h2 className="title__line">Most popular Cuisine For Client Demand</h2>
                        </div>
                    </div>
                </div>
                <div className="row mt--40">
                    {/* Start Single Clint Demand */}
                    <div className="col-lg-4 col-md-6">
                        <div className="clint__demand">
                            <div className="clnt__demand__thumb">
                                <img src="./assests/images/product/popular/1.png" alt="Demand images" /> 
                            </div>
                            <div className="clnt__thumb__hover">
                                <div className="clnt__thumb__hover__action wow zoomIn" data-wow-delay="0.2s">
                                    <img src="./assests/images/product/popular/sm-img/1.png" alt="small images" />
                                </div>
                                <div className="demand__food__prize">
                                    <span>$80</span>
                                </div>  
                            </div>
                            <div className="clint__demand__inner">
                                <h4><a href="menu-details.html">Friends Restaurant</a></h4>
                                <div className="clt__delevery__time">
                                    <p>Food Type :Mixed Fruit Custard</p>
                                    <p>Delivery Time : 60 min, Delivery Cost : Free</p>
                                </div>
                                <ul className="rating">
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* End Single Clint Demand */}
                    {/* Start Single Clint Demand */}
                    <div className="col-lg-4 col-md-6">
                        <div className="clint__demand">
                            <div className="clnt__demand__thumb">
                                <img src="./assests/images/product/popular/2.png" alt="Demand images" />
                            </div>
                            <div className="clnt__thumb__hover">
                                <div className="clnt__thumb__hover__action wow zoomIn" data-wow-delay="0.3s">
                                    <img src="./assests/images/product/popular/sm-img/2.png" alt="small images" />
                                </div>
                                <div className="demand__food__prize">
                                    <span>$80</span>
                                </div>  
                            </div>
                            <div className="clint__demand__inner">
                                <h4><a href="menu-details.html">Italian Pizza Fev</a></h4>
                                <div className="clt__delevery__time">
                                    <p>Food Type :Mixed Fruit Custard</p>
                                    <p>Delivery Time : 60 min, Delivery Cost : Free</p>
                                </div>
                                <ul className="rating">
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* End Single Clint Demand */}
                    {/* Start Single Clint Demand */}
                    <div className="col-lg-4 col-md-6">
                        <div className="clint__demand">
                            <div className="clnt__demand__thumb">
                                <img src="./assests/images/product/popular/1.png" alt="Demand images" />
                            </div>
                            <div className="clnt__thumb__hover">
                                <div className="clnt__thumb__hover__action wow zoomIn" data-wow-delay="0.4s">
                                    <img src="./assests/images/product/popular/sm-img/3.png" alt="small images" />
                                </div>
                                <div className="demand__food__prize">
                                    <span>$80</span>
                                </div>  
                            </div>
                            <div className="clint__demand__inner">
                                <h4><a href="menu-details.html">Asian Food Fev</a></h4>
                                <div className="clt__delevery__time">
                                    <p>Food Type :Mixed Fruit Custard</p>
                                    <p>Delivery Time : 60 min, Delivery Cost : Free</p>
                                </div>
                                <ul className="rating">
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li><i className="zmdi zmdi-star"></i></li>
                                    <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {/* End Single Clint Demand */}
                </div>
            </div>
        </section>
        {/* End Clint Demand Area */}
        {/* Start Testimonial Area */}
        <section className="fd__testimonial__area testimonial--2 bg-image--9 ptb--150">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="fd__testimonial__container testimonial__activation--2">
                            {/* Start Single Testimonial */}
                            <div className="single__testimonial d-flex justify-content-between flex-wrap">
                                {/* Start Testimonial */}
                                <div className="testimonial-2">
                                    <p>Lorem ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    <div className="fd__test__info">
                                        <h6>Mily Cyrus</h6>
                                        <span>Food Expert</span>
                                    </div>
                                </div>
                                {/* End Testimonial */}
                                {/* Start Testimonial */}
                                <div className="testimonial-2">
                                    <p>Lorem ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    <div className="fd__test__info">
                                        <h6>Mily Cyrus</h6>
                                        <span>Food Expert</span>
                                    </div>
                                </div>
                                {/* End Testimonial */}
                            </div>
                            {/* End Single Testimonial */}
                            {/* Start Single Testimonial */}
                            <div className="single__testimonial d-flex justify-content-between flex-wrap">
                                {/* Start Testimonial */}
                                <div className="testimonial-2">
                                    <p>Lorem ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    <div className="fd__test__info">
                                        <h6>Mily Cyrus</h6>
                                        <span>Food Expert</span>
                                    </div>
                                </div>
                                {/* End Testimonial */}
                                {/* Start Testimonial */}
                                <div className="testimonial-2">
                                    <p>Lorem ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    <div className="fd__test__info">
                                        <h6>Mily Cyrus</h6>
                                        <span>Food Expert</span>
                                    </div>
                                </div>
                                {/* End Testimonial */}
                            </div>
                            {/* End Single Testimonial */}
                            {/* Start Single Testimonial */}
                            <div className="single__testimonial d-flex justify-content-between flex-wrap">
                                {/* Start Testimonial */}
                                <div className="testimonial-2">
                                    <p>Lorem ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    <div className="fd__test__info">
                                        <h6>Mily Cyrus</h6>
                                        <span>Food Expert</span>
                                    </div>
                                </div>
                                {/* End Testimonial */}
                                {/* Start Testimonial */}
                                <div className="testimonial-2">
                                    <p>Lorem ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    <div className="fd__test__info">
                                        <h6>Mily Cyrus</h6>
                                        <span>Food Expert</span>
                                    </div>
                                </div>
                                {/* End Testimonial */}
                            </div>
                            {/* End Single Testimonial */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* End Testimonial Area */}
        {/* Start Subscribe Area */}
        <section className="fd__subscribe__area bg--white subscribe--2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="subscribe__inner">
                            <h2>Subscribe to our newsletter</h2>
                            <div id="mc_embed_signup">
                                <div id="enter__email__address">
                                    <form action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                        <div id="mc_embed_signup_scroll" className="htc__news__inner">
                                            <div className="news__input">
                                                <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Enter Your E-mail Address" required />
                                            </div>
                                            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true'><input type="text" name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef" tabIndex="-1" /></div>
                                            <div className="clearfix subscribe__btn"><input type="submit" value="Send Now" name="subscribe" id="mc-embedded-subscribe" className="sign__up food__btn" />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* End Subscribe Area */}
        {/* Start Banner Area */}
        <div className="banner__area d-flex instagram__activation owl-carousel owl-theme">
            <div className="banner">
                <img src="./assests/images/product/ins/1.jpg" alt="images" />
                <a href="#"><i className="zmdi zmdi-instagram"></i></a>
            </div>
            <div className="banner">
                <img src="./assests/images/product/ins/2.jpg" alt="images" />
                <a href="#"><i className="zmdi zmdi-instagram"></i></a>
            </div>
            <div className="banner">
                <img src="./assests/images/product/ins/3.jpg" alt="images" />
                <a href="#"><i className="zmdi zmdi-instagram"></i></a>
            </div>
            <div className="banner">
                <img src="./assests/images/product/ins/4.jpg" alt="images" />
                <a href="#"><i className="zmdi zmdi-instagram"></i></a>
            </div>
            <div className="banner">
                <img src="./assests/images/product/ins/5.jpg" alt="images" />
                <a href="#"><i className="zmdi zmdi-instagram"></i></a>
            </div>
        </div>
                <Footer />
                <SignupLogin />
                <Cart /> 
                </div>

        );

    }

}