import React, { Component } from 'react'  
import {Modal,Form} from 'react-bootstrap';
import Login_BLL from '../BLL/Login_BLL';
import {withRouter} from 'react-router';
import Header from './Header';  
import {Footer} from './Footer'; 
import SignupLogin from './SignupLogin';
import {Cart} from './Cart';
import $ from 'jquery';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

class SupplierCodeVerification extends Component{
   constructor(props){
      super(props);
   }


   handleChange = async (e) => {
      e.preventDefault();
      var login_bll = new Login_BLL();
      login_bll.SendMobileNo_SupplierRequest(this.props.location.state.Phone,this.props.location.state.Id).then((responseJsonMobile) => {
         if(!responseJsonMobile[0])
         {
           $( ".SupplierCodeResend" ).trigger( "click" );   
            
         }
      })
   }

   verifyCodeSuccess =() =>{
      toast.success('Code verified successfully.', {autoClose:10000})
   }
   
   verifyCodeError =() =>{
      toast.error('Invalid Code.', {autoClose:10000})
   }

   notifyVerificationCode =() =>{
      toast.success('Mobile Verification Code has been Resent.', {autoClose:10000})
   }

   handleSubmit = async (event) => {
      event.preventDefault();
      var login_bll = new Login_BLL();

      var dataToSend = {
                Code:event.target.Code.value
       };
       
       event.target.reset();
       await login_bll.SendVerficationCode_SupplierRequest(dataToSend.Code, this.props.location.state.Id).then((responseJson) => {
         if(!responseJson[0])
         {
           var result = JSON.stringify(responseJson);
           if (result.includes('Verified')) { 
            $( ".SupplierCodeVerifySuccess" ).trigger( "click" ); 
            this.props.history.push('/SupplierLogin');

          }else{
            $( ".SupplierCodeVerifyError" ).trigger( "click" ); 
          }
           this.setState({
             data: responseJson
          })
         }
       }
       )
    }

    render(){
        return(
            <div id="wrapper" className="wrapper">
            <Header />
            <h2 className="bradcaump-title">Verify Code</h2>
             <Modal.Body>
                     <Form onSubmit={this.handleSubmit}>
                     <Form.Group controlId ="Code">
                         <div>
                        <div className="single-input d-flex justify-content-center align-items-center" >
                           <Form.Control className="col-md-2" type="number" placeholder="Code" required name="Code"/>
                        </div>
                        </div>
                        </Form.Group>
                        <div className="single-input">
                           <button type="submit" className="food__btn"><span>Verify</span></button>
                        </div>
                     </Form>
                     <Form onSubmit={this.handleChange}> 
                     <div className="single-input">
                           <button type="submit"  className="" style={{marginTop:"10px"}}><span>Resend Code</span></button>
                        </div>
                        </Form>
                     </Modal.Body>
                     <button className="SupplierCodeVerifySuccess" onClick={this.verifyCodeSuccess} style={{display: "none"}}>Success</button>
                     <button className="SupplierCodeVerifyError" onClick={this.verifyCodeError} style={{display: "none"}}>Error</button>
                     <button className="SupplierCodeResend" onClick={this.notifyVerificationCode} style={{display: "none"}}>notifyVerificationCode</button>
                     <Footer />
                     <SignupLogin />
                     <Cart /> 
                  </div>
        );
    }
}

export default withRouter(SupplierCodeVerification)