

import React, { Component } from 'react'
// import { AsyncStorage } from 'react-native';


export default  class Constant extends Component {


     static URL="https://api.umda.com.pk"

     static TOKEN_KEY = null;
     static User_ID = '';
     static IsCurrentPostion = false;
     static Category_UserType = 'UserTypes';
     static Category_Cities = 'Cities';
     static Category_UPForGrab = 'Up for Grabs';
     static Category_Cakes = 'Cakes';
     static Category_SpecialOrders = 'Special Orders';
     static Category_Desserts = 'Desserts';
     static Category_EventsAndDinners = 'Events and Dinners';
     static Category_Supplier = 'Supplier';
     static Category_Customer = 'Customer';
     
    




   //   getUserIdAndAuthToken = async () => {

   //      //var ids = localStorage.getItem('User_ID').concat('/', localStorage.getItem('TOKEN'), '/', localStorage.getItem('UnitId'), '/', localStorage.getItem('subunitId'));
   //      var ids = await AsyncStorage.getItem('User_ID').concat('/', AsyncStorage.getItem('TOKEN'));
   //      //  console.log("Token"+ids);
   //      return ids;
        
   //   }


}