import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import $ from 'jquery';



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')

);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



$(document).on('click', '.accountbox-trigger', function(){
  var aa = localStorage.getItem('token')
  if(aa == 'NA'){
  $(".accountbox-wrapper").addClass("is-visible");
  }
});


$(document).on('click', '.accountbox-close-button', function(){
  $(".accountbox-wrapper").removeClass("is-visible");
});

$(document).on('click', '.minicart-trigger', function(){
  $(".cartbox-wrap").addClass("is-visible");
});

$(document).on('click', '.cartbox-close', function(){
  $(".cartbox-wrap").removeClass("is-visible");
});



$(document).on('click', '.SignupNotifySuccess', function(){
  
});
$(document).on('click', '.SignupNotifyError', function(){
  
});
$(document).on('click', '.SupplierSignupSuccess', function(){
  
});
$(document).on('click', '.SupplierSignupError', function(){
  
});
$(document).on('click', '.LoginNotifySuccess', function(){
   
});
$(document).on('click', '.LoginNotifyError', function(){
  
});
$(document).on('click', '.SupplierLoginSuccess', function(){
  
});
$(document).on('click', '.SupplierLoginError', function(){
  
});
$(document).on('click', '.CodeVerifySuccess', function(){
  
});
$(document).on('click', '.CodeVerifyError', function(){
  
});
$(document).on('click', '.SupplierCodeVerifySuccess', function(){
  
});
$(document).on('click', '.SupplierCodeVerifyError', function(){
  
});
$(document).on('click', '.ImageUploadError', function(){
  
});
$(document).on('click', '.FormSubmitError', function(){
  
});
$(document).on('click', 'SupplierCodeResend', function(){
  
});
$(document).on('click', 'ClientCodeResend', function(){
  
});
$(document).on('click', 'confirmAlert', function(){
  
});



















// $(document).on('click', '#log-tab', function(){
//   $("#log-tab").addClass("active");
//   $("#log-tab").attr("aria-selected","true");
//   $("#profile-tab").removeClass("active");
//   $("#profile-tab").attr("aria-selected","false");
//   $("a").attr("href", "#log");
// });


// $(document).on('click', '#profile-tab', function(){
//   $("#log-tab").removeClass("active");
//   $("#log-tab").attr("aria-selected","false");
//   $("#profile-tab").addClass("active");
//   $("#profile-tab").attr("aria-selected","true");
//   $("a").attr("href", "#profile");
// });
