import React, { Component } from 'react'  
import { Link } from 'react-router-dom'
import {withRouter} from 'react-router';
import Header from './Header';  
import {Footer} from './Footer'; 
import SignupLogin from './SignupLogin';
import {Cart} from './Cart';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import "bootstrap-less";
import $ from 'jquery';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
toast.configure();

class UpforGrab extends Component{

   constructor(props){
      super(props);
      this.state = {suppliers:[], count:0, activePage: 1, offset: 0, slice:"", countPerPage:9 }
   }


   componentDidMount(){
    fetch('https://api.umda.com.pk/api/GetAllSupplierDealsUpForGrab')
    .then(response => response.json())
    .then(data => {
        if(data != '')
        {
        var slicing = data.slice(this.state.offset, this.state.offset + this.state.countPerPage)
        this.setState({suppliers:slicing, count:data.length, slice:slicing})
        }else{
            toast.error('No Data Found.', {autoClose:10000})
            this.props.history.push('/Home');
        }
    })
}

handlePageChange(pageNumber) {
    var pageNo = pageNumber - 1;
    var offfset = pageNo * this.state.countPerPage;
    this.setState({activePage: pageNumber, offset: offfset });
    this.componentDidMount()
  }


  ADDTOCART =(e) =>{


    if(global.AddedToCartUpForGrab.length != 0){
    var data = JSON.stringify(global.AddedToCartUpForGrab)    
    var supplierId = data.includes(e.SupplierUserId)
    alert(supplierId)

    if(supplierId == true){
    var result = data.includes(e.DealId)
    if(result == false)
    {
        e.ItemQuantity = 1
        global.AddedToCartUpForGrab.push(e)
        global.MyCurrentAddress = global.MyCurrentAddress + 1
        global.GrandTotal = global.GrandTotal + e.DealPrice
    }
    else
    {
        var aa = global.AddedToCartUpForGrab.find((element) => element.DealId === e.DealId)
        aa.ItemQuantity += 1
        global.GrandTotal = global.GrandTotal + e.DealPrice
    }
    }else
    {

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to clear your cart.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {

                    toast.warning('Your Cart has been cleared.', {autoClose:10000})
                    global.MyCurrentAddress = 0
                    global.AddedToCartUpForGrab = []
                    global.GrandTotal = 0
                    
                    e.ItemQuantity = 1
                    global.AddedToCartUpForGrab.push(e)
                    global.MyCurrentAddress = global.MyCurrentAddress + 1
                    global.GrandTotal = global.GrandTotal + e.DealPrice

                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
          });
    }
}
    if(global.AddedToCartUpForGrab.length == 0){
        if(global.AddedToCart == ''){
        e.ItemQuantity = 1
        global.AddedToCartUpForGrab.push(e)
        global.MyCurrentAddress = global.MyCurrentAddress + 1
        global.GrandTotal = global.GrandTotal + e.DealPrice
        }
        else
        {

            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to clear your cart.',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
    
                        toast.warning('Your Cart has been cleared.', {autoClose:10000})
                        global.MyCurrentAddress = 0
                        global.AddedToCart = []
                        global.GrandTotal = 0
                        
                        e.ItemQuantity = 1
                        global.AddedToCartUpForGrab.push(e)
                        global.MyCurrentAddress = global.MyCurrentAddress + 1
                        global.GrandTotal = global.GrandTotal + e.DealPrice
    
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {
                        
                    }
                  }
                ]
              });

        }
    }

 }


   render() {    
 
  
    return(
        <div id="wrapper" className="wrapper">
        <Header />

            <div className="ht__bradcaump__area bg-image--17">
            <div className="ht__bradcaump__wrap d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="bradcaump__inner text-center">
                                <h2 className="bradcaump-title">Suppliers</h2>
                                <nav className="bradcaump-inner">
                                  {/* <a className="breadcrumb-item" href="index.html">Home</a> */}
                                  <span className="brd-separetor"><i className="zmdi zmdi-long-arrow-right"></i></span>
                                  {/* <span className="breadcrumb-item active">menu grid view</span> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         {/* End Bradcaump area */} 
         {/* Start Menu Grid Area */}
        <section className="food__menu__grid__area section-padding--lg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        {/* <div className="grid__show d-flex justify-content-between align-items-center">
                            <div className="grid__show__item">
                                <p>Showing 1-9 of 18 Result </p>
                            </div>
                            <div className="grid__show__btn">
                                <a className="food__btn" href="#">Default Sorting</a>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="row mt--30">
                {this.state.suppliers.map(cats =>
                    <div key ={cats.DealId} className="col-lg-4 col-sm-12 col-md-6">
                        <div className="menu__grid__item wow fadeInLeft">
                        <div className="menu__grid__thumb">
                                 <Link to={{ pathname: '/MenuGrid', state: { Id: cats.SupplierUserId} }}>
                                    <img src={"https://api.umda.com.pk/uploads/"+cats.PictureUrl} alt="grid item images" style={{width:"370px", height:"260px"}}/>
                                    </Link>
                            </div>
                            <div className="menu__grid__inner">
                                <div className="menu__grid__details">
                                    <h2><Link to={{ pathname: '/MenuGrid', state: { Id: cats.SupplierUserId} }}> {cats.CompanyName}</Link></h2>
                                    <p>{cats.DealName}</p>
                                    <ul className="grid__prize__list">
                                    <li class="old__prize">{cats.DealPrice}</li>
                                    <li>{cats.DealPrice}</li>
                                    </ul>
                                    
                                    {/* <p>
                                        {!cats.Address ? "N/A" : cats.Address}
                                    </p> */}
                                </div>
                                <div class="grid__addto__cart__btn">
                                <Link className="AddToCart"   to={{  state: { Checkout: cats.MenuName} }}  onClick={()=>{this.ADDTOCART(cats)}}>ADD TO CART</Link>
                                </div>
                                </div>
                        </div>
                    </div>
                )}
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <ul class="food__pagination d-flex justify-content-center align-items-center mt--130" >
                    <Pagination 
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.countPerPage}
                        totalItemsCount={this.state.count}
                        pageRangeDisplayed={50}
                        onChange={this.handlePageChange.bind(this)}

                        />
                        </ul>
                    </div>
                </div>
            </div>
        </section>

            <Footer />
            <SignupLogin />
            <Cart />
       </div>
    );
  }

}


export default withRouter(UpforGrab)