import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Login_BLL from '../BLL/Login_BLL';
import {withRouter} from 'react-router';
import Header from './Header';
import {Footer} from './Footer';
import SignupLogin from './SignupLogin';
import {toast} from 'react-toastify';
import {Cart} from './Cart';
import $, { data, event } from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import "bootstrap-less";
toast.configure();

class CheckoutPage extends Component {
    constructor(props){
        super(props);
        this.state = {cat:[], Name:"", NameError: "", Address:"", AddressError:"", City:"", CityError:"", Email:"", EmailError:"",
                      PhoneNo:"", PhoneNoError:"", CardName:"", CardNameError:"", CardNumber:"", CardNumberError:"", EXPDate:"",
                      EXPDateError:"", Year:"", YearError:"", VerificationNumber:"", VerificationNumberError:"", isDisabled:true,
                      ItemsList:[]
        }
     }



    loginHandler =() =>{
        $( ".accountbox-trigger" ).trigger( "click" );
     }
     
     registerHandler =() =>{
        $( ".accountbox-trigger" ).trigger( "click" );
     }

     handleMonthChange  = async(e) => {
      this.setState({
          EXPDate : e.target.value,
          EXPDateError: ""
      });
      }

      handleYearChange  = async(e) => {
        this.setState({
            Year : e.target.value,
            YearError: ""
        });
        }

     PlaceOrder=async(e)=>{
        e.preventDefault();
        if (this.state.Name === ''){
             this.setState({
                NameError:true
              })
              
         }

         if (this.state.Address === ''){
          this.setState({
             AddressError:true
           })
      }

      if (this.state.City === ''){
        this.setState({
           CityError:true
         })
    }
      if (this.state.Email === ''){
        this.setState({
          EmailError:true
        })
    }
      if (this.state.PhoneNo === ''){
      this.setState({
        PhoneNoError:true
      })
    }
     if (this.state.CardName === ''){
      this.setState({
        CardNameError:true
      })
    }  
    if (this.state.CardNumber === ''){
      this.setState({
        CardNumberError:true
      })
    }
    if (this.state.EXPDate === ''){
      this.setState({
        EXPDateError:true
      })
    }
    if (this.state.Year === ''){
      this.setState({
        YearError:true
      })
    }

    if (this.state.VerificationNumber === ''){
      this.setState({
        VerificationNumberError:true
      })
    }

    if(this.state.Name !=="" && this.state.Address!=="" && this.state.CardName!=="" && this.state.CardNumber!=="" && this.state.EXPDate!=="" && this.state.Year!=="" && this.state.VerificationNumber!=="" && 
    this.state.City !=="" && this.state.Email !=="" && this.state.PhoneNo !== "")
    {
      if(this.state.NameError=="" && this.state.AddressError=="" && this.state.CardNameError=="" && this.state.CardNumberError=="" && this.state.EXPDateError=="" && this.state.YearError=="" && this.state.VerificationNumberError=="" && 
      this.state.CityError=="" && this.state.EmailError=="" && this.state.PhoneNoError == "")
      {
       
         var login_bll = new Login_BLL();
     
         if(global.AddedToCart != ''){
         global.AddedToCart.forEach(element => {
           this.state.ItemsList.push({ItemId: element.MenuId, ItemName: element.MenuName, ItemPrice: element.Price, ItemDiscount:0, IsMenuItem:true, ItemQuantity: element.ItemQuantity} )
           global.SupplierUserId = element.SupplierUserId
         });
       }
       if(global.AddedToCartUpForGrab != ''){
     
         global.AddedToCartUpForGrab.forEach(element => {
           this.state.ItemsList.push({ItemId: element.MenuId, ItemName: element.MenuName, ItemPrice: element.Price, ItemDiscount:0, IsMenuItem:false , ItemQuantity: element.ItemQuantity} )
           global.SupplierUserId = element.SupplierUserId
         });
       }
     
         var dataToSend = {
           UserId:global.User.UserId ,
           SupplierUserId:global.SupplierUserId,
           PaymentTypeId:12,
           DeliveryTypeId:12,
           DeliveryAddress:this.state.Address,
           OrderStatus:"New_Order",
           lstUserOrderDetails:this.state.ItemsList,
     
     };
     alert(JSON.stringify(dataToSend))
     console.log(JSON.stringify(dataToSend))
     login_bll.add_OrderRequest(dataToSend).then((responseJson) => {
     if(!responseJson[0])
     {
      var result = JSON.stringify(responseJson);
      alert(result)
      if (result.includes('Successfull')) { 
     
        global.MyCurrentAddress = 0
        global.AddedToCart = []
        global.AddedToCartUpForGrab = []
        global.GrandTotal = 0
        global.ShippingCost = 100
        global.SupplierUserId = ''
       this.props.history.push('/Home'); 
       toast.success('Your order has been placed successfully.', {autoClose:10000})
     }
     else
     {    
      toast.error('Error found in result.', {autoClose:10000})
     }
     
        }
        else{
          toast.error('Response Json Error.', {autoClose:10000})
        }
       });
         
      }
     else
     {
      toast.error('Please correct your data before placing your order.', {autoClose:10000})
     }
    }
    else
    {
      toast.error('Please fill in data before placing your order.', {autoClose:10000})
    }


 


    }

     handleChange(e){
        if(e.target.name==='Name'){
           if(e.target.value.length > 100 ){
             this.setState({
               NameError:true
             })
           } else {
             this.setState({
               NameError:false,     
               Name:e.target.value
             })
           }
         }

         if(e.target.name==='Address'){
            if(e.target.value.length > 500 ){
              this.setState({
                AddressError:true
              })
            } else {
              this.setState({
                AddressError:false,     
                Address:e.target.value
              })
            }
          }

          if(e.target.name==='City'){
            if(e.target.value.length > 100 ){
              this.setState({
                CityError:true
              })
            } else {
              this.setState({
                CityError:false,     
                City:e.target.value
              })
            }
          }

          if(e.target.name==='Email'){
            if(e.target.value.length > 100 ){
              this.setState({
                EmailError:true
              })
            } else {
              this.setState({
                EmailError:false,     
                Email:e.target.value
              })
            }
          }

          if(e.target.name==='PhoneNo'){
            if(e.target.value.length < 10 || e.target.value.length > 12 ){
                this.setState({
                  PhoneNoError:true
                })
            } else {
              this.setState({
                PhoneNoError:false,     
                PhoneNo:e.target.value
              })
            }
          }

          if(e.target.name==='CardName'){
            if(e.target.value.length > 100 ){
              this.setState({
                CardNameError:true
              })
            } else {
              this.setState({
                CardNameError:false,     
                CardName:e.target.value
              })
            }
          }

          if(e.target.name==='CardNumber'){
            if(e.target.value.length > 100 ){
              this.setState({
                CardNumberError:true
              })
            } else {
              this.setState({
                CardNumberError:false,     
                CardNumber:e.target.value
              })
            }
          }

          if(e.target.name==='EXPDate'){
            if(e.target.value.length > 100 ){
              this.setState({
                EXPDateError:true
              })
            } else {
              this.setState({
                EXPDateError:false,     
                EXPDate:e.target.value
              })
            }
          }

          if(e.target.name==='Year'){
            if(e.target.value.length > 100 ){
              this.setState({
                YearError:true
              })
            } else {
              this.setState({
                YearError:false,     
                Year:e.target.value
              })
            }
          }

          if(e.target.name==='VerificationNumber'){
            if(e.target.value.length > 100 ){
              this.setState({
                VerificationNumberError:true
              })
            } else {
              this.setState({
                VerificationNumberError:false,     
                VerificationNumber:e.target.value
              })
            }
          }

     }

     WhatHandler(){
        toast.info('3 Digits Code on the Back of Your Card.', {autoClose:10000})
     }


    render(){
        return(
                <div id="wrapper" className="wrapper">
                    <Header />
                    <div class="ht__bradcaump__area bg-image--18">
            <div class="ht__bradcaump__wrap d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="bradcaump__inner text-center">
                                <h2 class="bradcaump-title">Checkout</h2>
                                <nav class="bradcaump-inner">
                                  {/* <a class="breadcrumb-item" href="index.html">Home</a> */}
                                  <span class="brd-separetor"><i class="zmdi zmdi-long-arrow-right"></i></span>
                                  {/* <span class="breadcrumb-item active">Checkout</span> */}
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End Bradcaump area */} 
         <section class="htc__checkout bg--white section-padding--lg">
            {/* Checkout Section Start*/}
            <div class="checkout-section">
                <div class="container">
                    <div class="row">
                       
                        <div class="col-lg-6 col-12 mb-30">
                               
                                {/* Checkout Accordion Start */}
                                <div id="checkout-accordion">
                                   
                                    {/* Checkout Method */}
                                    <div class="single-accordion">
                                        <a class="accordion-head" data-toggle="collapse" data-parent="#checkout-accordion" href="#checkout-method">1. checkout method</a>
                                        <div id="checkout-method" class="collapse show">
                                            <div class="checkout-method accordion-body fix">
                                               
                                                <ul class="checkout-login-form">
                                                    {global.User == '' ? <div class="input-box col-12"><input type="submit" value="Login" onClick={this.loginHandler}/></div> : <div class="input-box col-12"><input type="submit" value="Logged In"/></div>}
                                                    {global.User == '' ? <div class="input-box col-12"><input type="submit" value="Register" style={{marginLeft:'10px'}} onClick={this.registerHandler}/></div> : ''}
                                                    
                                                   
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {/* Billing Method */}
                                    <div class="single-accordion">
                                        <a class="accordion-head collapsed" data-toggle="collapse" data-parent="#checkout-accordion" href="#billing-method">2. billing information</a>
                                        <div id="billing-method" class="collapse">

                                            <div class="accordion-body billing-method fix">

                                                <form action="#" class="billing-form checkout-form">
                                                    <div class="row">
                                                        <div class="col-md-12 col-12 mb--20">                                 
                                                            <input type="text" placeholder="Name" onChange={(e)=>{this.handleChange(e)}} name="Name" />
                                                            {this.state.NameError ? <span style={{color: "red"}}>Invalid Character Length</span> : ''}
                                                        </div>
                                                        <div class="col-12 mb--20">
                                                            <input placeholder="Address" type="text" onChange={(e)=>{this.handleChange(e)}} name="Address"/>
                                                            {this.state.AddressError ? <span style={{color: "red"}}>Invalid Character Length</span> : ''}
                                                        </div>
                                                        <div class="col-12 mb--20">
                                                            <input placeholder="City" type="text" onChange={(e)=>{this.handleChange(e)}} name="City"/>
                                                            {this.state.CityError ? <span style={{color: "red"}}>Invalid Character Length</span> : ''}
                                                        </div>
                                                        <div class="col-md-6 col-12">                                 
                                                            <input type="email" placeholder="Email Address" onChange={(e)=>{this.handleChange(e)}} name="Email"/>
                                                            {this.state.EmailError ? <span style={{color: "red"}}>Invalid Character Length</span> : ''}
                                                        </div>
                                                        <div class="col-md-6 col-12">                                   
                                                            <input placeholder="Phone Number" type="number" onChange={(e)=>{this.handleChange(e)}} name="PhoneNo"/>
                                                            {this.state.PhoneNoError ? <span style={{color: "red"}}>Invalid Mobile No</span> : ''}
                                                        </div>                          
                                                    </div>
                                                </form>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    {/* Payment Method */}
                                    <div class="single-accordion">
                                        <a class="accordion-head collapsed" data-toggle="collapse" data-parent="#checkout-accordion" href="#payment-method">3. Payment method</a>
                                        <div id="payment-method" class="collapse">
                                            <div class="accordion-body payment-method fix">
                                            <ul class="payment-method-list">
                                                    <li class="">Debit Card / Credit Card</li>
                                                </ul>
                                                
                                                <form action="#" class="payment-form" style={{display:'block'}}>
                                                    <div class="row">
                                                        <div class="input-box col-12 mb--20">
                                                            <label for="card-name">Name on Card *</label>
                                                            <input type="text" id="card-name" onChange={(e)=>{this.handleChange(e)}} name="CardName"/>
                                                            {this.state.CardNameError ? <span style={{color: "red"}}>Invalid Character Length</span> : ''}

                                                        </div>
                                                        <div class="input-box col-12 mb--20">
                                                            <label for="card-number">Card Number *</label>
                                                            <input type="number" id="card-number" onChange={(e)=>{this.handleChange(e)}} name="CardNumber"/>
                                                            {this.state.CardNumberError ? <span style={{color: "red"}}>Invalid Card Number</span> : ''}
                                                        </div>
                                                        <div class="input-box col-12">
                                                            <div class="row">
                                                                <div class="input-box col-12">
                                                                    <label>Expiration Date</label>
                                                                </div>
                                                                <div class="input-box col-md-6 col-12 mb--20">
                                                                    <select   Value={this.state.selectValue} 
                                                                              onChange={this.handleMonthChange} >
                                                                        <option Value="">Please Select Month</option>
                                                                        <option Value="Jan">Jan</option>
                                                                        <option Value="Feb">Feb</option>
                                                                        <option Value="Mar">Mar</option>
                                                                        <option Value="Apr">Apr</option>
                                                                        <option Value="May">May</option>
                                                                        <option Value="Jun">Jun</option>
                                                                        <option Value="Jul">Jul</option>
                                                                        <option Value="Aug">Aug</option>
                                                                        <option Value="Sep">Sep</option>
                                                                        <option Value="Oct">Oct</option>
                                                                        <option Value="Nov">Nov</option>
                                                                        <option Value="Dec">Dec</option>
                                                                    </select>
                                                                    {this.state.EXPDateError ? <span style={{color: "red"}}>Please Select Month</span> : ''}
                                                                </div>
                                                                <div class="input-box col-md-6 col-12 mb--20">
                                                                    <select  Value={this.state.selectValue} 
                                                                              onChange={this.handleYearChange}>
                                                                        <option Value="">Please Select Year</option>
                                                                        <option Value="2015">2015</option>
                                                                        <option Value="2016">2016</option>
                                                                        <option Value="2017">2017</option>
                                                                        <option Value="2018">2018</option>
                                                                        <option Value="2019">2019</option>
                                                                        <option Value="2020">2020</option>
                                                                        <option Value="2021">2021</option>
                                                                        <option Value="2022">2022</option>
                                                                        <option Value="2023">2023</option>
                                                                        <option Value="2024">2024</option>
                                                                        <option Value="2025">2025</option>
                                                                    </select>
                                                                    {this.state.YearError ? <span style={{color: "red"}}>Please Select Card Year</span> : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="input-box col-12">
                                                            <label for="card-Verify">Card Verification Number</label>
                                                            <input type="text" id="card-Verify" onChange={(e)=>{this.handleChange(e)}} name="VerificationNumber"/>
                                                            <div>{this.state.VerificationNumberError ? <span style={{color: "red"}}>Invalid Character Length</span> : ''}</div>
                                                            <a href="#" onClick={()=>{this.WhatHandler()}}>What is it ?</a>
                                                        </div>
                                                    </div>
                                                </form>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>{/* Checkout Accordion Start */}
                            </div>
                            
                            {/* Order Details */}
                            <div class="col-lg-6 col-12 mb-30">
                               
                                <div class="order-details-wrapper">
                                    <h2>your order</h2>
                                    <div class="order-details">
                                        <form action="#">
                                        {global.AddedToCart != '' ?
                                            <ul>
                                                <li><p class="strong">product</p><p class="strong">total</p></li>
                                                {global.AddedToCart.map(cats =>
                                                <li key ={cats.MenuId}><p>{cats.MenuName} x {cats.ItemQuantity}</p><p>{cats.Price * cats.ItemQuantity}</p></li>
                                                )}
                                                <li><p class="strong">cart subtotal</p><p class="strong">{global.GrandTotal}</p></li>
                                                <li><p class="strong">shipping</p><p>
                                                <label for="flat">Flat Rate : {global.ShippingCost}</label><br />
                                                </p></li>
                                                <li><p class="strong">order total</p><p class="strong">{global.GrandTotal + global.ShippingCost}</p></li>
                                                {global.User != '' ? <li><button class="food__btn" onClick={(e)=>{this.PlaceOrder(e)}}>place order</button></li> : ''}
                                            </ul>
                                            : ''}

                                            {global.AddedToCartUpForGrab != '' ?
                                            <ul>
                                                <li><p class="strong">product</p><p class="strong">total</p></li>
                                                {global.AddedToCartUpForGrab.map(cats =>
                                                <li key ={cats.DealId}><p>{cats.DealName} x {cats.ItemQuantity}</p><p>{cats.DealPrice * cats.ItemQuantity}</p></li>
                                                )}
                                                <li><p class="strong">cart subtotal</p><p class="strong">{global.GrandTotal}</p></li>
                                                <li><p class="strong">shipping</p><p>
                                                <label for="flat">Flat Rate : {global.ShippingCost}</label><br />
                                                </p></li>
                                                <li><p class="strong">order total</p><p class="strong">{global.GrandTotal + global.ShippingCost}</p></li>
                                                {global.User != '' ? <li><button class="food__btn" onClick={(e)=>{this.PlaceOrder(e)}}>place order</button></li> : ''}
                                            </ul>
                                            : ''}


                                        </form>
                                    </div>
                                </div>
                                
                            </div>
                        
                    </div>
                </div>
            </div>{/* Checkout Section End*/}             
         </section>   
                    <Footer />
                    <SignupLogin />
                    <Cart />

                </div>

        );
    }

}


export default withRouter(CheckoutPage)