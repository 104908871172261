import React, { Component } from 'react'  
import { Link } from 'react-router-dom'
import {withRouter} from 'react-router';
import Header from './Header';  
import {Footer} from './Footer'; 
import SignupLogin from './SignupLogin';
import {Cart} from './Cart';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pagination from "react-js-pagination";
import "bootstrap-less";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
toast.configure();

class MenuDetails extends Component{
   constructor(props){
      super(props);
      this.state = {Menu:[], count:1}
   }

   componentDidMount(){

    fetch('https://api.umda.com.pk/api/GetMenuByMenuId?MenuId='+this.props.location.state.Id)
    .then(response => response.json())
    .then(data => {
        var resultdata = JSON.stringify(data)
        if(resultdata.includes('invalid')){
            this.props.history.push('/Home');
        }
        else
        {
            this.setState({Menu:data})
        }
    })
}

  handleIncChange  = async(e) => {
      var counter = this.state.count + 1
    this.setState({count:counter})
}
  handleDecChange  = async(e) => {
      if(this.state.count > 1){
    var decCounter = this.state.count - 1
    this.setState({count:decCounter})
      }
}

ADDTOCART =(e) =>{


    if(global.AddedToCart.length != 0){
    var data = JSON.stringify(global.AddedToCart)
    var supplierId = data.includes(e.SupplierUserId)
    
    if(supplierId == true){
    var result = data.includes(e.MenuId)
    if(result == false)
    {
        e.ItemQuantity = this.state.count
        global.AddedToCart.push(e)
        global.MyCurrentAddress = global.MyCurrentAddress + 1
        var diffPrice = e.ItemQuantity * e.Price
        global.GrandTotal = global.GrandTotal + diffPrice
    }
    else
    {
        var aa = global.AddedToCart.find((element) => element.MenuId === e.MenuId)
        var oldPrice = aa.ItemQuantity * aa.Price
        global.GrandTotal = global.GrandTotal - oldPrice
        aa.ItemQuantity = this.state.count
        var diffPrice1 = aa.ItemQuantity * e.Price
        global.GrandTotal = global.GrandTotal + diffPrice1
    }
    }
    else
    {
        
        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to clear your cart.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => {

                    toast.warning('Your Cart has been cleared.', {autoClose:10000})
                    global.MyCurrentAddress = 0
                    global.AddedToCart = []
                    global.GrandTotal = 0
            
                    e.ItemQuantity = this.state.count
                    global.AddedToCart.push(e)
                    global.MyCurrentAddress = global.MyCurrentAddress + 1
                    global.GrandTotal = e.ItemQuantity * e.Price

                }
              },
              {
                label: 'No',
                onClick: () => {
                    
                }
              }
            ]
          });

    }
}
    if(global.AddedToCart.length == 0){

        if(global.AddedToCartUpForGrab == ''){
        e.ItemQuantity = this.state.count
        global.AddedToCart.push(e)
        global.MyCurrentAddress = global.MyCurrentAddress + 1
        global.GrandTotal = e.ItemQuantity * e.Price
        }
        else
        {

            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to clear your cart.',
                buttons: [
                  {
                    label: 'Yes',
                    onClick: () => {
    
                        toast.warning('Your Cart has been cleared.', {autoClose:10000})
                        global.MyCurrentAddress = 0
                        global.AddedToCartUpForGrab = []
                        global.GrandTotal = 0
            
                        e.ItemQuantity = this.state.count
                        global.AddedToCart.push(e)
                        global.MyCurrentAddress = global.MyCurrentAddress + 1
                        global.GrandTotal = e.ItemQuantity * e.Price
    
                    }
                  },
                  {
                    label: 'No',
                    onClick: () => {
                        
                    }
                  }
                ]
              });

        }
    }

 }



   render() {    
 
    return(
        <div id="wrapper" className="wrapper">
            <Header />
            <section className="blog__list__view section-padding--lg menudetails-right-sidebar bg--white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <div className="food__menu__container">
                            <div className="food__menu__inner d-flex flex-wrap flex-md-nowrap flex-lg-nowrap">
                                <div className="food__menu__thumb">
                                    <img src={"https://api.umda.com.pk/uploads/"+this.state.Menu.PictureUrl} alt="images"  style={{height:'370px', width:'380px'}}/>
                                </div>
                                <div className="food__menu__details">
                                    <div className="food__menu__content">
                                    <h2>{this.state.Menu.MenuName}</h2>
                                        <ul className="food__dtl__prize d-flex">
                                            <li className="old__prize">{this.state.Menu.Price}</li>
                                            <li>{this.state.Menu.Price}</li>
                                        </ul>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod temp incid dunt ut labo dolore magna aliqua. Ut enim ad minim veniaquis nostrud exercit ullamco laboris nisi ut aliq.</p>
                                        <div className="product-action-wrap">
                                            <div className="prodict-statas"><span>Food Type : {this.state.Menu.CuisineName}</span></div>
                                            <div className="product-quantity">
                                                <form id='myform' method='POST' action='#'>
                                                    <div className="product-quantity">
                                                        <div className="cart-plus-minus">
                                                            <input className="cart-plus-minus-box" type="text" name="qtybutton" value={this.state.count} />
                                                            <div className="add__to__cart__btn">
                                                            <Link className="food__btn"   to={{  state: { Checkout: this.state.Menu.CuisineName} }}  onClick={()=>{this.ADDTOCART(this.state.Menu)}}>Add To Cart</Link>
                                                            </div>
                                                            <div className="dec qtybutton" onClick={this.handleDecChange}><span>-</span></div>
                                                            <div className="inc qtybutton" onClick={this.handleIncChange}><span>+</span></div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Start Product Descrive Area */}
                            <div className="menu__descrive__area">
                                <div className="menu__nav nav nav-tabs" role="tablist">
                                    <a className="active" id="nav-all-tab" data-toggle="tab" href="#nav-all" role="tab">Description</a>
                                    {/* <a id="nav-breakfast-tab" data-toggle="tab" href="#nav-breakfast" role="tab">Reviews</a> */}
                                </div>
                                {/* Start Tab Content */}
                                <div className="menu__tab__content tab-content" id="nav-tabContent">
                                    {/* Start Single Content */}
                                    <div className="single__dec__content fade show active" id="nav-all" role="tabpanel">
                                    <p>{this.state.Menu.Description}</p>
                                    </div>
                                    {/* End Single Content */}
                                    {/* Start Single Content */}
                                    <div className="single__dec__content fade" id="nav-breakfast" role="tabpanel">
                                        <div className="review__wrapper">
                                            {/* Start Single Review */}
                                            <div className="single__review d-flex">
                                                <div className="review__thumb">
                                                    <img src="./assests/images/testimonial/rev/1.jpg" alt="review images" />
                                                </div>
                                                <div className="review__details">
                                                    <h3>Robart Hanson</h3>
                                                    <div className="rev__meta d-flex justify-content-between">
                                                        <span>Admin - February  13,  2018</span>
                                                        <ul className="rating">
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Review */}
                                            {/* Start Single Review */}
                                            <div className="single__review d-flex">
                                                <div className="review__thumb">
                                                    <img src="./assests/images/testimonial/rev/2.jpg" alt="review images" />
                                                </div>
                                                <div className="review__details">
                                                    <h3>Robart Hanson</h3>
                                                    <div className="rev__meta d-flex justify-content-between">
                                                        <span>Admin - February  13,  2018</span>
                                                        <ul className="rating">
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                            <li><i className="fa fa-star" aria-hidden="true"></i></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Single Review */}
                                        </div>
                                    </div>
                                    {/* End Single Content */}
                                </div>
                                {/* End Tab Content */}
                            </div>
                            {/* End Product Descrive Area */}
                        </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-12 col-sm-12 md--mt--40 sm--mt--40">
                        <div className="food__sidebar">
                            <div className="food__search">
                                <h4 className="side__title">Search</h4>
                                <div className="serch__box">
                                    <input type="text" placeholder="Search keyword"  value="Default"/>
                                    <a href="#"><i className="fa fa-search"></i></a>
                                </div>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
        </section>

            <Footer />
            <SignupLogin />
            <Cart />
       </div>
    );
  }


}


export default withRouter(MenuDetails)