import React, { Component } from 'react'  
import {Modal, Button, Row, Col, Form} from 'react-bootstrap';
import Login_BLL from '../BLL/Login_BLL';
import {withRouter} from 'react-router';
import Header from './Header';  
import {Footer} from './Footer'; 
import SignupLogin from './SignupLogin';
import {Cart} from './Cart';
import $ from 'jquery';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure();

class SupplierLogin extends Component{
   constructor(props){
      super(props);
   }


   loginNotifySuccess =() =>{
      toast.success('Logged In Successfully.', {autoClose:10000})
   }
   
   loginNotifyError =() =>{
      toast.error('Invalid Credentials.', {autoClose:10000})
   }
   
   handleSubmit = async (event) => {
      event.preventDefault();
      
      var login_bll = new Login_BLL();

      var dataToSend = {
                Email:event.target.Email.value,
                Password:event.target.Password.value
       };
       event.target.reset();
       await login_bll.login_SupplierRequest(dataToSend).then((responseJson) => {
         if(!responseJson[0])
         {
            var result = JSON.stringify(responseJson);
            if (result.includes('Successfull')) { 

                $( ".SupplierLoginSuccess" ).trigger( "click" );
                this.props.history.push('/Home');

            this.setState({
                data: responseJson
             })
            }
            else
            {
               $( ".SupplierLoginError" ).trigger( "click" );
            }

         }

       })
    }

    render(){
        return(
            <div id="wrapper" className="wrapper">
            <Header />
            <h2 className="bradcaump-title">Supplier Login</h2>
            <div>
                  <Modal.Body>
                     <Form onSubmit={this.handleSubmit}>
                        <Form.Group controlId ="Email">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="cr-round--lg col-md-3" type="email" placeholder="Email address" required name="Email" />
                        </div>
                        </Form.Group>
                        <Form.Group controlId ="Password">
                        <div className="single-input d-flex justify-content-center align-items-center">
                           <Form.Control className="cr-round--lg col-md-3" type="password" placeholder="Password" required name="Password"/>
                        </div>
                        </Form.Group>
                        <div className="single-input">
                           <button type="submit" className="food__btn"><span>Login</span></button>
                        </div>
                     </Form>
                     </Modal.Body>
                     </div>
                     <button className="SupplierLoginSuccess" onClick={this.loginNotifySuccess} style={{display: "none"}}>Success</button>
                     <button className="SupplierLoginError" onClick={this.loginNotifyError} style={{display: "none"}}>Error</button>
                     <Footer />
                     <SignupLogin />
                     <Cart /> 
                  </div>

        );
    }

}

export default withRouter(SupplierLogin)