import React from 'react';
import './App.css';
import Layout from './Components/Layout';
import { Redirect } from 'react-router'

function App() {


  global.MyCurrentAddress = 0
  global.AddedToCart = []
  global.AddedToCartUpForGrab = []
  global.UserName = ''
  global.GrandTotal = 0
  global.ShippingCost = 100
  global.User = ''
  global.SupplierUserId = ''
  localStorage.setItem('token', 'NA')
  localStorage.setItem('UserAddress', 'NA')
 

  //localStorage.setItem('userName', '')
  return (
    <div className="App">

     <Layout />
    </div>
  );
}

export default App;
