import React, { Component } from 'react';
import Header from './Header';  

export class HomeBox extends Component{
    render(){
        return(
          <div className="wrapper box__layout" id="wrapper">
              <Header />
               <div className="slider__area slider--four">
            <div className="slider__activation--1">
               {/* Start Single Slide */}
               <div className="slide slider__fixed--height bg-image--14 poss--relative">
                  <div className="container">
                     <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                           <div className="slider__content">
                              <div className="slider__inner">
                                 <h1><img src="./assests/images/slider/text/3.jpg" alt="slider text img" /></h1>
                                 <div className="slider__video__btn">
                                    <a className="video-play-button" href="https://www.youtube.com/watch?v=cxB4ACaaR4I"><img src="./assests/images/icon/play.png" alt="viveo play icon" /></a>
                                 </div>
                                 <div className="slider__btn">
                                    <a className="food__btn" href="#">Read More</a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               {/* End Single Slide */}
            </div>
         </div>
         {/* End Slider Area */}
         {/* Start Popular Food Area */}
         <section className="popular__food__area section-padding--lg bg--white">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-md-12">
                     <div className="section__title title__style--2 service__align--center section__bg__white">
                        <h2 className="title__line">Our Popular Food Menu</h2>
                        <p>The process of our service </p>
                     </div>
                  </div>
               </div>
               <div className="row mt--30">
                  {/* Start Single Popular Food */}
                  <div className="col-lg-4 col-md-6 col-sm-12 foo">
                     <div className="popular__food">
                        <div className="pp_food__thumb">
                           <a href="menu-details.html">
                           <img src="./assests/images/popular/1.jpg" alt="popular food" />
                           </a>
                           <div className="pp__food__prize">
                              <span>$50</span>
                           </div>
                        </div>
                        <div className="pp__food__inner">
                           <div className="pp__fd__icon">
                              <img src="./assests/images/popular/icon/1.png" alt="icon images" />
                           </div>
                           <div className="pp__food__details">
                              <h4><a href="menu-details.html">Food Type : Donuts</a></h4>
                              <ul className="rating">
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                              </ul>
                              <p>Delivery Time : 60 min, Free Delivery</p>
                              <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                 <div className="pp__btn">
                                    <a className="food__btn btn--transparent btn__hover--theme btn__hover--theme" href="#">Order Online</a>
                                 </div>
                                 <ul className="pp__meta d-flex">
                                    <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                    <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* End Single Popular Food */}
                  {/* Start Single Popular Food */}
                  <div className="col-lg-4 col-md-6 col-sm-12 foo">
                     <div className="popular__food">
                        <div className="pp_food__thumb">
                           <a href="menu-details.html">
                           <img src="./assests/images/popular/2.jpg" alt="popular food" />
                           </a>
                           <div className="pp__food__prize">
                              <span>$50</span>
                           </div>
                        </div>
                        <div className="pp__food__inner">
                           <div className="pp__fd__icon">
                              <img src="./assests/images/popular/icon/2.png" alt="icon images" />
                           </div>
                           <div className="pp__food__details">
                              <h4><a href="menu-details.html">Food Type : Muffin</a></h4>
                              <ul className="rating">
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                              </ul>
                              <p>Delivery Time : 60 min, Free Delivery</p>
                              <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                 <div className="pp__btn">
                                    <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                 </div>
                                 <ul className="pp__meta d-flex">
                                    <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                    <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* End Single Popular Food */}
                  {/* Start Single Popular Food */}
                  <div className="col-lg-4 col-md-6 col-sm-12 foo">
                     <div className="popular__food">
                        <div className="pp_food__thumb">
                           <a href="menu-details.html">
                           <img src="./assests/images/popular/3.jpg" alt="popular food" />
                           </a>
                           <div className="pp__food__prize">
                              <span>$40</span>
                           </div>
                        </div>
                        <div className="pp__food__inner">
                           <div className="pp__fd__icon">
                              <img src="./assests/images/popular/icon/3.png" alt="icon images" />
                           </div>
                           <div className="pp__food__details">
                              <h4><a href="menu-details.html">Food Type : Bun</a></h4>
                              <ul className="rating">
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                              </ul>
                              <p>Delivery Time : 60 min, Free Delivery</p>
                              <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                 <div className="pp__btn">
                                    <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                 </div>
                                 <ul className="pp__meta d-flex">
                                    <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                    <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* End Single Popular Food */}
                  {/* Start Single Popular Food */}
                  <div className="col-lg-4 col-md-6 col-sm-12 foo">
                     <div className="popular__food">
                        <div className="pp_food__thumb">
                           <a href="menu-details.html">
                           <img src="./assests/images/popular/4.jpg" alt="popular food" />
                           </a>
                           <div className="pp__food__prize">
                              <span>$40</span>
                           </div>
                        </div>
                        <div className="pp__food__inner">
                           <div className="pp__fd__icon">
                              <img src="./assests/images/popular/icon/4.png" alt="icon images" />
                           </div>
                           <div className="pp__food__details">
                              <h4><a href="menu-details.html">Food Type : Cup Cake</a></h4>
                              <ul className="rating">
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                              </ul>
                              <p>Delivery Time : 60 min, Free Delivery</p>
                              <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                 <div className="pp__btn">
                                    <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                 </div>
                                 <ul className="pp__meta d-flex">
                                    <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                    <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* End Single Popular Food */}
                  {/* Start Single Popular Food */}
                  <div className="col-lg-4 col-md-6 col-sm-12 foo">
                     <div className="popular__food">
                        <div className="pp_food__thumb">
                           <a href="menu-details.html">
                           <img src="./assests/images/popular/5.jpg" alt="popular food" />
                           </a>
                           <div className="pp__food__prize">
                              <span>$40</span>
                           </div>
                        </div>
                        <div className="pp__food__inner">
                           <div className="pp__fd__icon">
                              <img src="./assests/images/popular/icon/5.png" alt="icon images" />
                           </div>
                           <div className="pp__food__details">
                              <h4><a href="menu-details.html">Food Type : Donuts</a></h4>
                              <ul className="rating">
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                              </ul>
                              <p>Delivery Time : 60 min, Free Delivery</p>
                              <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                 <div className="pp__btn">
                                    <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                 </div>
                                 <ul className="pp__meta d-flex">
                                    <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                    <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* End Single Popular Food */}
                  {/* Start Single Popular Food */}
                  <div className="col-lg-4 col-md-6 col-sm-12 foo">
                     <div className="popular__food">
                        <div className="pp_food__thumb">
                           <a href="menu-details.html">
                           <img src="./assests/images/popular/6.jpg" alt="popular food" />
                           </a>
                           <div className="pp__food__prize">
                              <span>$40</span>
                           </div>
                        </div>
                        <div className="pp__food__inner">
                           <div className="pp__fd__icon">
                              <img src="./assests/images/popular/icon/6.png" alt="icon images" />
                           </div>
                           <div className="pp__food__details">
                              <h4><a href="menu-details.html">Food Type : Bread</a></h4>
                              <ul className="rating">
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li><i className="zmdi zmdi-star"></i></li>
                                 <li className="rating__opasity"><i className="zmdi zmdi-star"></i></li>
                              </ul>
                              <p>Delivery Time : 60 min, Free Delivery</p>
                              <div className="pp__food__bottom d-flex justify-content-between align-items-center">
                                 <div className="pp__btn">
                                    <a className="food__btn btn--transparent btn__hover--theme" href="#">Order Online</a>
                                 </div>
                                 <ul className="pp__meta d-flex">
                                    <li><a href="#"><i className="zmdi zmdi-comment-outline"></i>03</a></li>
                                    <li><a href="#"><i className="zmdi zmdi-favorite-outline"></i>04</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* End Single Popular Food */}
               </div>
            </div>
         </section>
         {/* End Popular Food Area */}
         {/* Start Blog Area */}
         <section className="fb__blog__ara pb--130 bg--white">
            <div className="container">
               <div className="row">
                  <div className="col-md-12 col-lg-12">
                     <div className="col-lg-12 col-md-12">
                        <div className="section__title title__style--2 service__align--center">
                           <h2 className="title__line">Latest News Blog</h2>
                           <p>The process of our service </p>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="row mt--40">
                  {/* Start Single Blog */}
                  <div className="col-md-6 col-lg-4 foo col-sm-12">
                     <div className="blog">
                        <div className="blog__thumb">
                           <a href="blog-details.html">
                           <img src="./assests/images/blog/md-blog/4.jpg" alt="blog images" />
                           </a>
                        </div>
                        <div className="blog__details">
                           <h2><a href="blog-details.html">Maxican Food Fev</a></h2>
                           <span>1st Oct, 2o17</span>
                           <p>Lorem ipsum dolor sit amadipisicing elit, seddo eiusmoddolore magna aliqua. Ut enim ad miveniam, quis noion ullamco laboris nisi umt aliquip ex ea cequat.</p>
                           <div className="blog__btn">
                              <a className="food__btn btn--green" href="blog-details.html">Read More</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* Start Single Blog */}
                  {/* Start Single Blog */}
                  <div className="col-md-6 col-lg-4 foo col-sm-12">
                     <div className="blog">
                        <div className="blog__thumb">
                           <a href="blog-details.html">
                           <img src="./assests/images/blog/md-blog/5.jpg" alt="blog images" />
                           </a>
                        </div>
                        <div className="blog__details">
                           <h2><a href="blog-details.html">Asian Food F</a></h2>
                           <span>1st Oct, 2o17</span>
                           <p>Lorem ipsum dolor sit amadipisicing elit, seddo eiusmoddolore magna aliqua. Ut enim ad miveniam, quis noion ullamco laboris nisi umt aliquip ex ea cequat.</p>
                           <div className="blog__btn">
                              <a className="food__btn btn--green" href="blog-details.html">Read More</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* Start Single Blog */}
                  {/* Start Single Blog */}
                  <div className="col-md-6 col-lg-4 foo col-sm-12">
                     <div className="blog">
                        <div className="blog__thumb">
                           <a href="blog-details.html">
                           <img src="./assests/images/blog/md-blog/6.jpg" alt="blog images" />
                           </a>
                        </div>
                        <div className="blog__details">
                           <h2><a href="blog-details.html">Italian Pizza Fevv</a></h2>
                           <span>1st Oct, 2o17</span>
                           <p>Lorem ipsum dolor sit amadipisicing elit, seddo eiusmoddolore magna aliqua. Ut enim ad miveniam, quis noion ullamco laboris nisi umt aliquip ex ea cequat.</p>
                           <div className="blog__btn">
                              <a className="food__btn btn--green" href="blog-details.html">Read More</a>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* Start Single Blog */}
               </div>
            </div>
         </section>
         {/* End Blog Area */}
         {/* Start Testimonial Area */}
         <section className="food__testimonial__area testimonial--4 bg-image--15">
            <div className="container">
               <div className="row">
                  <div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
                     <div className="testimonia__activation">
                        {/* Start Single Testimonial */}
                        <div className="testimonial-inner--4">
                           <div className="testimonial__inner">
                              {/* Start Testimonial Text */}
                              <div className="testimonial-text-slider">
                                 <div className="testimonial-text-content">
                                    <div className="test__icon">
                                       <i className="zmdi zmdi-quote"></i>
                                    </div>
                                    <div className="testimonial__content">
                                       <p>Lorem ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    </div>
                                 </div>
                                 <div className="testimonial-text-content">
                                    <div className="test__icon">
                                       <i className="zmdi zmdi-quote"></i>
                                    </div>
                                    <div className="testimonial__content">
                                       <p>Nipa ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    </div>
                                 </div>
                                 <div className="testimonial-text-content">
                                    <div className="test__icon">
                                       <i className="zmdi zmdi-quote"></i>
                                    </div>
                                    <div className="testimonial__content">
                                       <p>Kohinur ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    </div>
                                 </div>
                                 <div className="testimonial-text-content">
                                    <div className="test__icon">
                                       <i className="zmdi zmdi-quote"></i>
                                    </div>
                                    <div className="testimonial__content">
                                       <p>Maksud Vaia ipsum dolor samconsectetuadipisicing elit, kjjnin khk seeiusmod tempor incididunt ut labore et dolore maaliqua. veniam,</p>
                                    </div>
                                 </div>
                              </div>
                              {/* End Testimonial Text */}
                              {/* Start Testimonial Images */}
                              <div className="testimonial-image-slider">
                                 <div className="test__info">
                                    <div className="test__img__thumb">
                                       <img src="./assests/images/testimonial/clint-2/1.png" alt="testimonial images" />
                                    </div>
                                    <h4>Browny</h4>
                                    <span>Food Lovers</span>
                                 </div>
                                 <div className="test__info">
                                    <div className="test__img__thumb">
                                       <img src="./assests/images/testimonial/clint-2/1.png" alt="testimonial images" />
                                    </div>
                                    <h4>Irin Pervin</h4>
                                    <span>Food Lovers</span>
                                 </div>
                                 <div className="test__info">
                                    <div className="test__img__thumb">
                                       <img src="./assests/images/testimonial/clint-2/1.png" alt="testimonial images" />
                                    </div>
                                    <h4>Najnin Supa</h4>
                                    <span>Food Lovers</span>
                                 </div>
                                 <div className="test__info">
                                    <div className="test__img__thumb">
                                       <img src="./assests/images/testimonial/clint-2/1.png" alt="testimonial images" />
                                    </div>
                                    <h4>Hanchika Browny</h4>
                                    <span>Food Lovers</span>
                                 </div>
                              </div>
                              {/* End Testimonial Images */}
                           </div>
                        </div>
                        {/* End Single Testimonial */}
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/* End Testimonial Area */}
         {/* Start Counter Up Area */}
         <section className="fd__counterup__area bg--theme fanfact--2">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <div className="counter__up__inner d-flex flex-wrap flex-lg-nowrap justify-content-between text-center">
                        {/* Start Single Fact */}
                        <div className="funfact">
                           <div className="fact__details">
                              <div className="funfact__count__inner">
                                 <div className="fact__icon">
                                    <img src="./assests/images/icon/flat-icon/4.png" alt="flat icon" />
                                 </div>
                                 <div className="fact__count ">
                                    <span className="count">2456</span>
                                 </div>
                              </div>
                              <div className="fact__title">
                                 <h2>Food </h2>
                              </div>
                           </div>
                        </div>
                        {/* End Single Fact */}
                        {/* Start Single Fact */}
                        <div className="funfact">
                           <div className="fact__details">
                              <div className="funfact__count__inner">
                                 <div className="fact__icon">
                                    <img src="./assests/images/icon/flat-icon/5.png" alt="flat icon" />
                                 </div>
                                 <div className="fact__count ">
                                    <span className="count">2456</span>
                                 </div>
                              </div>
                              <div className="fact__title">
                                 <h2>Chef </h2>
                              </div>
                           </div>
                        </div>
                        {/* End Single Fact */}
                        {/* Start Single Fact */}
                        <div className="funfact">
                           <div className="fact__details">
                              <div className="funfact__count__inner">
                                 <div className="fact__icon">
                                    <img src="./assests/images/icon/flat-icon/6.png" alt="flat icon" />
                                 </div>
                                 <div className="fact__count ">
                                    <span className="count">2456</span>
                                 </div>
                              </div>
                              <div className="fact__title">
                                 <h2>Menu </h2>
                              </div>
                           </div>
                        </div>
                        {/* End Single Fact */}
                        {/* Start Single Fact */}
                        <div className="funfact">
                           <div className="fact__details">
                              <div className="funfact__count__inner">
                                 <div className="fact__icon">
                                    <img src="./assests/images/icon/flat-icon/7.png" alt="flat icon" />
                                 </div>
                                 <div className="fact__count ">
                                    <span className="count">2456</span>
                                 </div>
                              </div>
                              <div className="fact__title">
                                 <h2>Restaurant </h2>
                              </div>
                           </div>
                        </div>
                        {/* End Single Fact */}
                     </div>
                  </div>
               </div>
            </div>
         </section>
         {/* End Counter Up Area */}
         {/* Start Our Brand Area */}
         <div className="food__brand__area ptb--150">
            <div className="container">
               <div className="row">
                  <div className="col-md-12">
                     <ul className="brand__list d-flex flex-wrap flex-lg-nowrap justify-content-between pb--60">
                        <li><a href="#"><img src="./assests/images/brand/1.png" alt="brand images" /></a></li>
                        <li><a href="#"><img src="./assests/images/brand/2.png" alt="brand images" /></a></li>
                        <li><a href="#"><img src="./assests/images/brand/3.png" alt="brand images" /></a></li>
                        <li><a href="#"><img src="./assests/images/brand/4.png" alt="brand images" /></a></li>
                        <li><a href="#"><img src="./assests/images/brand/5.png" alt="brand images" /></a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
         {/* End Our Brand Area */}
         {/* Start Subscribe Area */}
         <section className="fd__subscribe__wrapper bg__cat--6 subs--4">
            <div className="fd__subscribe__area">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12 col-md-12">
                        <div className="subscribe__inner subscribe--3">
                           <h2>Subscribe to our newsletter</h2>
                           <div id="mc_embed_signup">
                             <div id="enter__email__address">
                                    <form action="http://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                        <div id="mc_embed_signup_scroll" className="htc__news__inner">
                                            <div className="news__input">
                                            {/* <input type="email" value="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder="Enter Your E-mail Address" required /> */}
                                            </div>
                                            {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
                                            <div style={{position: 'absolute', left: '-5000px', ariaHidden:'true' }}><input type="text" name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef" tabIndex="-1" value="tab" /></div>
                                            <div className="clearfix subscribe__btn"><input type="submit" value="Send Now" name="subscribe" id="mc-embedded-subscribe" className="sign__up food__btn" />
                                            </div>
                                        </div>
                                    </form>
                              </div>
                            </div>
                           <div className="subs__address__content d-flex justify-content-between">
                              <div className="subs__address d-flex">
                                 <div className="sbs__address__icon">
                                    <i className="zmdi zmdi-home"></i>
                                 </div>
                                 <div className="subs__address__details">
                                    <p>Elizabeth Tower. 6th Floor <br /> Medtown, New York</p>
                                 </div>
                              </div>
                              <div className="subs__address d-flex">
                                 <div className="sbs__address__icon">
                                    <i className="zmdi zmdi-phone"></i>
                                 </div>
                                 <div className="subs__address__details">
                                    <p><a href="#">+088 01673-453290</a></p>
                                    <p><a href="#">+088 01773-458290</a></p>
                                 </div>
                              </div>
                              <div className="subs__address d-flex">
                                 <div className="sbs__address__icon">
                                    <i className="zmdi zmdi-email"></i>
                                 </div>
                                 <div className="subs__address__details">
                                    <p><a href="#">Aahardelivery@email.com</a></p>
                                    <p><a href="#">deliverydotnet@e-mail.com</a></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <footer className="footer__area footer--4">
            <div className="copyright bg__cat--7">
               <div className="container">
                  <div className="row">
                     <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="copyright__inner">
                           <div className="cpy__right--left">
                              <p>@All Right Reserved.<a href="https://devitems.com">Devitems</a></p>
                           </div>
                           <div className="cpy__right--right">
                              <a href="#">
                              <img src="./assests/images/icon/shape/2.png" alt="payment images" />
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
              </div>
              

        );
    }
}