import React, { Component } from 'react'  
import { Link } from 'react-router-dom'
import {toast} from 'react-toastify';
toast.configure();

  
export class Cart extends Component { 
   constructor(props){
      super(props);
      this.state = {Menus:[]}
   }
   
   CheckOutHandler() 
   {
      if(global.AddedToCart != 0 || global.AddedToCartUpForGrab != 0)
      {
      }
      else
      {
         toast.error('No Items to Checkout.', {autoClose:10000})
         this.props.history.push('/Home');
      }
    }

    DeleteMenu(e) 
    {
      var index = global.AddedToCart.indexOf(e)
      var price = e.Price * e.ItemQuantity
      global.GrandTotal -= price
      if (index !== -1) {
         global.AddedToCart.splice(index, 1);
         global.MyCurrentAddress = global.MyCurrentAddress - 1
         this.setState(this.state);
      }
    }

    DeleteMenuCart(e) 
    {
      var index = global.AddedToCartUpForGrab.indexOf(e)
      var price = e.DealPrice * e.ItemQuantity
      global.GrandTotal -= price
      if (index !== -1) {
         global.AddedToCartUpForGrab.splice(index, 1);
         global.MyCurrentAddress = global.MyCurrentAddress - 1
         this.setState(this.state);
      }
    }

   //  CartClose() 
   //  {
   //    alert('Cart Closed')
   //    alert(global.MyCurrentAddress)
   //    global.MyCurrentAddress = global.MyCurrentAddress
   //    this.setState(this.state);
   //  }


    render() {
        return (
            <div className="cartbox-wrap">
            <div className="cartbox text-right">
               <button className="cartbox-close" ><i className="zmdi zmdi-close"></i></button>
               <div className="cartbox__inner text-left">
               {global.AddedToCart != '' ?           
                  <div className="cartbox__items">
                  {global.AddedToCart.map(cats =>
                     <div key ={cats.MenuId} className="cartbox__item">
                  
                        <div className="cartbox__item__thumb">
                                 <Link to={{ pathname: '/MenuDetails', state: { Id: cats.MenuId} }}>
                                    <img src={"https://api.umda.com.pk/uploads/"+cats.PictureUrl} alt="small thumbnail" style={{width:"70px", height:"70px"}}/>
                                </Link>
                        </div>
                        <div className="cartbox__item__content">
                        <h5><a href="product-details.html" className="product-name">{cats.MenuName}</a></h5>
                         <p>Qty: <span>{cats.ItemQuantity}</span></p>
                           <span className="price">{cats.Price * cats.ItemQuantity}</span>
                        </div>
                        <button className="cartbox__item__remove">
                        <i className="fa fa-trash" onClick={()=>{this.DeleteMenu(cats)}}></i>
                        </button>
                     </div>
                     )}
                  </div>
                      : ''}

               {global.AddedToCartUpForGrab != '' ?  
                  <div className="cartbox__items">
                  {global.AddedToCartUpForGrab.map(cats =>
                     <div key ={cats.DealId} className="cartbox__item">
                  
                        <div className="cartbox__item__thumb">
                                 {/* <Link to={{ pathname: '/MenuDetails', state: { Id: cats.MenuId} }}> */}
                                    <img src={"https://api.umda.com.pk/uploads/"+cats.PictureUrl} alt="small thumbnail" style={{width:"70px", height:"70px"}}/>
                                {/* </Link> */}
                        </div>
                        <div className="cartbox__item__content">
                        <h5><a href="product-details.html" className="product-name">{cats.DealName}</a></h5>
                         <p>Qty: <span>{cats.ItemQuantity}</span></p>
                           <span className="price">{cats.DealPrice * cats.ItemQuantity}</span>
                        </div>
                        <button className="cartbox__item__remove">
                        <i className="fa fa-trash" onClick={()=>{this.DeleteMenuCart(cats)}}></i>
                        </button>
                     </div>
                     )}
                  </div>
                     : ''}


                  <div className="cartbox__total">
                     <ul>
                        <li><span className="cartbox__total__title">Subtotal</span><span className="price">{global.GrandTotal}</span></li>
                        <li className="shipping-charge"><span className="cartbox__total__title">Shipping Charges</span><span className="price">{global.ShippingCost}</span></li>
                        <li className="grandtotal">Total<span className="price">{global.GrandTotal + global.ShippingCost}</span></li>
                     </ul>
                  </div>
                  <div className="cartbox__buttons">
                     {/* <a className="food__btn" href="cart.html"><span>View cart</span></a> */}
                     
                     {global.AddedToCart != '' || global.AddedToCartUpForGrab != '' ? <Link className="food__btn" to={{ pathname: '/CartView'}}><span>View cart</span></Link> : ''}
                     {global.AddedToCart != '' || global.AddedToCartUpForGrab != '' ? <Link className="food__btn" to={{ pathname: '/CheckoutPage'}} onClick={()=>{this.CheckOutHandler()}}><span>Checkout</span></Link> : ''}
                     
                     {/* <a className="food__btn" href="checkout.html"><span>Checkout</span></a> */}
                  </div>
               </div>
            </div>
         </div>
  
        );
    }
}
