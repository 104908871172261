import React from 'react';
import {Modal,Form, Button, Toast} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
   
  class Test extends React.Component {

   
    render() {
      return (
        <div>
        Favorite Food: <FontAwesomeIcon icon="location" />
        <i class="fa fa-map-marker"></i>
      </div>
      );
    }
  }

export default Test;